
import {useTheme} from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import React, {useEffect, useState} from 'react';
import styled from "styled-components";

const isTouchDevice = 'ontouchstart' in document.documentElement;
const SWIPER_HEIGHT = 60;

const SwipeableButton = ({label, activeLabel, direction, onComplete, type}) => {
    const theme = useTheme();
    const [position, setPosition] = useState(SWIPER_HEIGHT);
    const [swipeComplete, setSwipeComplete] = useState(false); // did a drag activate the button
    const [dragStarted, setDragStarted] = useState(false); // has a drag started

    useEffect(() => {
        setPosition(SWIPER_HEIGHT); // px value == swiper height
        setSwipeComplete(false);
    }, [label])

    const dragStart = (event, isTouch = false) => {
        if (isTouch === isTouchDevice) { // Prevent multiple event handling
            setDragStarted(true);
            window.navigator.vibrate([50]);
        }
    };

    const dragActive = (event, isTouch = false) => {
        if (isTouch === isTouchDevice) {
            let containerWidth = event.target.parentElement.clientWidth;
            const swiperPosition = isTouch && isTouchDevice ? event.touches[0].clientX : event.clientX;
    
            if (dragStarted && !swipeComplete) {
                const fullWidth = containerWidth;
                setPosition(Math.min(direction === "right" ? swiperPosition + SWIPER_HEIGHT / 4 : fullWidth - swiperPosition + (SWIPER_HEIGHT * 3 / 4), fullWidth));
            }
        }
    };

    const dragEnd = (event, isTouch = false) => {
        if (isTouch === isTouchDevice) {
            const fullWidth = event.target.parentElement.clientWidth;

            if ((fullWidth * 6 / 10) > position && !swipeComplete) {
                setPosition(SWIPER_HEIGHT);
            } else if (!swipeComplete) {
                setPosition(fullWidth);
                setSwipeComplete(true);
                onComplete(); // call the function bound to the button
                window.navigator.vibrate([50,30,50,30,50]);
            }

            setDragStarted(false);
        }
    };

    return (
        <Container theme={theme} size={SWIPER_HEIGHT}
            onTouchEnd={(event) => dragEnd(event, true)}
            onMouseUp={(event) => dragEnd(event)}>
            <Label size={SWIPER_HEIGHT}>{label}</Label>
            <SwiperGlobal className={direction} direction={direction} type={type} theme={theme} size={SWIPER_HEIGHT}
                onTouchStart={(event) => dragStart(event, true)}
                onTouchMove={(event) => dragActive(event, true)}
                onMouseDown={(event) => dragStart(event)}
                onMouseMove={(event) => dragActive(event)}
                position={position}>
                <ActiveText complete={swipeComplete} size={SWIPER_HEIGHT}>{activeLabel}</ActiveText>
                <DoubleArrowIcon />
            </SwiperGlobal>
        </Container>
    );
};

export default SwipeableButton;

const Container = styled.div`
    height: ${({size}) => size}px;
    width: calc(100%);
    border-radius: ${({size}) => size / 2}px;
    overflow: hidden;
    background: lightgrey;
    margin: 0.25rem 0;
    
    color: ${({theme}) => theme.palette.background.lighter};
`;
const Label = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, ${({size}) => size / 3}px);
`;

const ActiveText = styled.span`
    opacity: ${({complete}) => complete ? "0.75" : "0"};
    width: calc(100% - ${({size}) => size}px);
    transition: 300ms ease;
    text-align: center;
    font-weight: 700;
`;

const SwiperGlobal = styled.div.attrs((props) => ({
    style: {
        left: `${props.direction === "left" ? "calc(100% - " + props.position + "px)" : "unset"}`,
        right: `${props.direction === "right" ? "calc(100% - " + props.position + "px)" : "unset"}`,
        width: `calc(100% - 62.5px ${props.position}px)`
    }
}))`
display: flex;
justify-content: flex-end;
align-items: center;

height: ${({size}) => size}px;

border-radius: ${({size}) => size / 2}px;

top: 50%;

position: relative;
transform: translate(0, -50%);

color: ${({theme}) => theme.palette.background.lighter};

svg {
    pointer-events: none;
}

&.left {
    flex-direction: row-reverse;
    padding-left: ${({size}) => size / 2 - 12}px;
    background: ${({theme, type}) => type ? (theme.palette?.[type]?.main || theme.palette.warning.main) : theme.palette.warning.main};

    svg {
        transform: rotate(180deg);
    }
}

&.right {
    padding-right: ${({size}) => size / 2 - 12}px;
    background: ${({theme, type}) => type ? (theme.palette?.[type]?.main || theme.palette.success.main) : theme.palette.success.main};
}`;