import {
    CLOCK_IN_EMPLOYEE,
    EMPLOYEE_CLOCK_IN_REQUEST_FAILED,
    EMPLOYEE_CLOCK_IN_REQUEST_SUCCESS,
    EMPLOYEE_CLOCK_IN_RESPONSE_FAILED,
    EMPLOYEE_CLOCK_IN_RESPONSE_SUCCESS,
    EMPLOYEE_CLOCK_OUT_REQUEST_SUCCESS,
    REQUEST_CLOCK_IN_EMPLOYEE,
    RESET_EMPLOYEE_NUMBER, SET_CREW
} from "../actions/crew";
import {SET_VEHICLE_STATUS} from "../actions/vehicle";

const DEFAULT_STATE = {
    crew: {},
    currentEmployee: null
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case SET_CREW:
            return {...state, crew: {...action.payload}}
        case CLOCK_IN_EMPLOYEE:
            return {...state, currentEmployee: `AWAITING ${action.payload}`}
        case REQUEST_CLOCK_IN_EMPLOYEE:
            return {...state, currentEmployee: `AWAITING ${action.payload}`}
        case RESET_EMPLOYEE_NUMBER:
            return {...state, currentEmployee: null}
        case EMPLOYEE_CLOCK_IN_REQUEST_FAILED:
            return {...state, currentEmployee: `Error: ${action.payload}`}
        case EMPLOYEE_CLOCK_IN_REQUEST_SUCCESS:
            return {...state, currentEmployee: `Success: ${action.payload}`}
        case EMPLOYEE_CLOCK_IN_RESPONSE_FAILED:
            return {...state, currentEmployee: `Error: ${action.payload}`}
        case EMPLOYEE_CLOCK_IN_RESPONSE_SUCCESS:
            return {
                ...state,
                currentEmployee: `ClockedIn: ${action.payload.message}`,
                crew: {...state.crew, [action.payload.employee.employee_number]: action.payload.employee}
            }
        case EMPLOYEE_CLOCK_OUT_REQUEST_SUCCESS: {
            const crew = {...state.crew}
            delete crew[action.payload.employee.employee_number]
            return {
                ...state,
                crew: {...crew}
            }
        }
        case SET_VEHICLE_STATUS:
            return {
                ...state,
                crew: {...action.payload.crew}
            }
        default:
            return state;
    }
}
