import {resendQueuedMessages} from "../../actions/socket";
import {updateVehicleStatus} from "../../actions/vehicle";
import {getItemFromStorage} from "../../middleware/components/storage";

const TIMER_INTERVAL = 5;
let timerIteration = 0;
let wakeLock = null;

const calcTimerIteration = (time) => {
    return Math.ceil(time / TIMER_INTERVAL);
};

const requestWakeLock = async () => {
    try {
        wakeLock = await navigator.wakeLock.request('screen');
    } catch (err) {
        console.error(err);
    }
};

// Basic timer runs every 5 seconds
export const startAppTimer = async (dispatch) => {
    const runningInApp = getItemFromStorage("runningInApp");

    await requestWakeLock();

    const updater = setInterval(() => {
        timerIteration++;

        if (timerIteration && !(timerIteration % calcTimerIteration(60))) {
            dispatch(updateVehicleStatus({}));
        }

        if (timerIteration && !(timerIteration % calcTimerIteration(30))) {
            dispatch(resendQueuedMessages({}));
        }
    }, TIMER_INTERVAL * 1000);

    return updater;
};

export const stopAppTimer = () => {
    timerIteration = 0;
    wakeLock.release();
    wakeLock = null;
};