import React, {useEffect, useState} from 'react';
import TextInputCard from "../../components/textInputCard";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {clockInEmployee, requestClockInEmployee, resetEmployee} from "../../../redux/actions/crew";
import Card from "../../components/card";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import SimpleDialog from "../../components/dialog";
import Break from "../../components/break";
import {setActiveTab} from '../../../redux/actions/application';

const EmployeeSignIn = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [employeeNumber, setEmployeeNumber] = useState("");
    const employee = useSelector(state => state.crew.currentEmployee);
    const dispatch = useDispatch();
    const theme = useTheme();
    const history = useHistory();


    const navigateToHome = () => {
        dispatch(setActiveTab("Home"));
        history.replace("/");
    };

    const onRequestClockIn = (employeeNo) => {
        dispatch(requestClockInEmployee(employeeNo));
    };

    const onClockIn = () => {
        dispatch(clockInEmployee(employeeNumber));
    };

    const isEmployeeError = () => {
        if (!employee) return false;
        if (!employee.startsWith('Error: ')) return false;
        return employee.replace("Error: ", "");
    };

    const isEmployeeSuccess = () => {
        if (!employee) return false;
        if (!employee.startsWith('Success: ')) return false;
        return employee.replace("Success: ", "");
    };

    const employeeClockedInSuccess = () => {
        if (!employee) return false;
        if (!employee.startsWith('ClockedIn: ')) return false;
        return employee.replace("ClockedIn: ", "");
    };

    useEffect(() => {
        if (isEmployeeError()) setDialogOpen(true);
        if (isEmployeeSuccess()) setDialogOpen(true);
        if (employeeClockedInSuccess()) setDialogOpen(true);
    }, [employee]);

    const isError = () => {
        return isNaN(employeeNumber) || !employeeNumber || employeeNumber.length < 4 || employee !== null;
    };

    const renderWaitingResponse = () => {
        return (
            <React.Fragment>
                <p>Awaiting clock-in response for <EmployeeSpan
                    color={theme.palette.myERA.green}>{employeeNumber}</EmployeeSpan></p>
            </React.Fragment>
        );
    };

    const renderError = () => {
        return (
            <React.Fragment>
                <DialogTextPadding>
                    {isEmployeeError()}
                </DialogTextPadding>
                <DialogButtonDiv>
                    <DialogButton
                        variant={"outlined"}
                        onClick={() => {
                            dispatch(resetEmployee());
                            setDialogOpen(false);
                            history.goBack();
                        }}
                    >
                        OK
                    </DialogButton>
                </DialogButtonDiv>

            </React.Fragment>
        );
    };

    const renderClockedIn = () => {
        return (
            <React.Fragment>
                <DialogTextPadding>
                    {employeeClockedInSuccess()}
                </DialogTextPadding>
                <DialogButtonDiv>
                    <DialogButton
                        variant={"outlined"}
                        onClick={() => {
                            dispatch(resetEmployee());
                            setDialogOpen(false);
                            navigateToHome();
                        }}
                    >
                        OK
                    </DialogButton>
                </DialogButtonDiv>

            </React.Fragment>
        );
    };

    const renderConfirmation = () => {
        return (
            <React.Fragment>
                <DialogTextPadding>
                    {isEmployeeSuccess()}
                </DialogTextPadding>
                <DialogButtonDiv>
                    <DialogButton
                        variant={'outlined'}
                        onClick={() => {
                            dispatch(resetEmployee());
                            setDialogOpen(false);
                            history.goBack();
                        }}
                    >
                        No
                    </DialogButton>
                    <DialogButton
                        variant={'outlined'}
                        onClick={() => {
                            onClockIn();
                            setDialogOpen(false);
                        }}
                    >
                        Yes
                    </DialogButton>
                </DialogButtonDiv>

            </React.Fragment>
        );
    };

    const renderEmployeeNumberInput = () => {
        return (
            <TextInputCard
                dismissable
                onDismiss={() => {
                    history.goBack();
                }}
                title={"Crew clock-in"}
                label={"Employee Number"}
                prompt={"Please enter your employee number below"}
                onSubmit={onRequestClockIn}
                buttonText={"Clock In"}
                onChange={setEmployeeNumber}
                disabled={isError()}
                error={(employeeNumber.length > 0 && isError()) ? "Please enter a valid employee number (at least 4 digits)" : ""}
            />
        );
    };

    const renderStep = () => {
        if (employee && employee.startsWith("AWAITING"))
            return (
                <Card dismissable onDismiss={() => {
                    dispatch(resetEmployee());
                }}>
                    {renderWaitingResponse()}
                </Card>
            );
        if (dialogOpen)
            return (
                <Card dismissable onDismiss={() => {
                    dispatch(resetEmployee());
                }}>
                    <p>Employee Clock In Dialog Open</p>
                    <Break />
                </Card>
            );
        return renderEmployeeNumberInput();
    };

    return (
        <React.Fragment>
            {renderStep()}
            <SimpleDialog open={dialogOpen} title={isEmployeeError() ? <Error>Error</Error> : "Employee Confirmation"}>
                <Padding>
                    {isEmployeeError() && renderError()}
                    {isEmployeeSuccess() && renderConfirmation()}
                    {employeeClockedInSuccess() && renderClockedIn()}
                </Padding>
            </SimpleDialog>
        </React.Fragment>

    );
};

export default EmployeeSignIn;

const Padding = styled.div`
  padding: 10px;
`;

const EmployeeSpan = styled.span`
  color: ${({color}) => color};
`;

const DialogTextPadding = styled.div`
  padding: 0 5px 10px 10px;
`;

const DialogButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
`;

const Error = styled.div`
  color: red;
`;

const DialogButton = styled(Button)`
  width: 45%;
`;