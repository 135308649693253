import React, {useState} from 'react';
import Card from "./card";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Break from "./break";


const TextInputCard = ({error, onSubmit, label, buttonText, prompt, disabled, onChange, dismissable, onDismiss, title, type}) => {
    const [text, setText] = useState("");

    return (
        <Card
            dismissable={dismissable}
            onDismiss={onDismiss}
            title={title}
        >
            {prompt}
            <Break margin={".5rem"}/>
            <TextField
                style={{width: "100%"}}
                id={label.toLowerCase().replace(' ', '_')}
                value={text}
                error={!!error}
                helperText={error}
                type={type || "text"}
                onChange={(e) => {
                    onChange && onChange(e.target.value)
                    setText(e.target.value)
                }}
                label={label}/>
            <Break margin={"1rem"}/>
            <Button
                style={{width: "100%"}}
                variant="outlined"
                color="primary"
                disabled={disabled}
                onClick={() => onSubmit(text)}>
                {buttonText}
            </Button>
        </Card>
    );
};

export default TextInputCard;
