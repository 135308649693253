export const generateUUID = () => {
    var time = new Date().getTime();
    var timeSinceLoad = (performance && performance.now && (performance.now() * 1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character) => {
        var randomNum = Math.random() * 16; //random number between 0 and 16

        if (time > 0) {
            //Use timestamp until depleted
            randomNum = (time + randomNum) % 16 | 0;
            time = Math.floor(time / 16);
        } else {
            //Use microseconds since page-load if supported
            randomNum = (timeSinceLoad + randomNum) % 16 | 0;
            timeSinceLoad = Math.floor(timeSinceLoad / 16);
        }

        return (character === 'x' ? randomNum : ((randomNum & 0x3) | 0x8)).toString(16);
    });
};
