import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {IdentifierType, MEDICAL_AIDS} from "../../../constants";
import React, {useEffect} from "react";
import styled from "styled-components";
import {Field, useForm, useFormState} from 'react-final-form'
import {
    requiredDateValidator, requiredSAIdValidator,
    requiredValidator
} from "../Utils/validators";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {ExpandMore} from "@material-ui/icons";
import {RemoveButton, StyledAutoComplete, StyledTextField} from "./styledPatientInfoFields";
import {dateFromValidSAID, renderPatientName} from "./utils";

const RFFToggleGroup = ({input, meta, children, ...props}) => {
    return <ToggleButtonGroup {...input} {...props}>{children}</ToggleButtonGroup>
}

const PatientCaptureSection = ({ name, index, theme, open, setOpen, remove }) => {
    const { change, resetFieldState, getFieldState } = useForm();
    const { values: formValues } = useFormState();

    const values = formValues?.patients?.[index];
    const { first_names, last_name, identifier, identifier_type, medical_aid_scheme } = values

    useEffect(() => {
        if (identifier_type === IdentifierType.NNZAF) {
            const dob = getFieldState(`${name}.identifier`).valid ? dateFromValidSAID(identifier) : undefined;
            change(`${name}.date_of_birth`, dob ?? undefined)
            resetFieldState(`${name}.date_of_birth`)
        }
    }, [identifier])

    useEffect(() => {
        change(`${name}.identifier`, undefined)
        change(`${name}.date_of_birth`, undefined)

        resetFieldState(`${name}.identifier`)
        resetFieldState(`${name}.date_of_birth`)
    }, [identifier_type])

    useEffect(() => {
        if (!medical_aid_scheme) {
            change(`${name}.medical_aid_scheme`, undefined)
            change(`${name}.medical_aid_number`, undefined)
            resetFieldState(`${name}.medical_aid_scheme`)
            resetFieldState(`${name}.medical_aid_number`)
        }
    }, [medical_aid_scheme])

    return (
        <Accordion expanded={open === `patient${index}`} onChange={setOpen(`patient${index}`)} >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`patient${index}-content`}
                id={`patient${index}-header`}
            >
                {renderPatientName(index, first_names, last_name)}
            </AccordionSummary>
            <AccordionDetails>
                <Spacer>
                    {/* First Names */}
                    <StyledTextField
                        name={`${name}.first_names`}
                        label="First name(s)"
                        theme={theme}
                        fieldProps={{
                            validate: requiredValidator('Please provide a first name(s)'),
                        }}
                    />
                    {/* Last Name */}
                    <StyledTextField
                        name={`${name}.last_name`}
                        label="Last name"
                        theme={theme}
                        fieldProps={{
                            validate: requiredValidator('Please provide a last name')
                        }}
                    />
                    {/* ID Type */}
                    <Flex>
                        <Instruction>ID Type</Instruction>
                        <Field
                            required
                            exclusive
                            name={`${name}.identifier_type`}
                            onChange={(event, value) => {
                                change(`${name}.identifier_type`, value)
                            }}
                            component={RFFToggleGroup}
                            validate={requiredValidator('Please select the type of identification')}
                        >
                            <ToggleButton value={IdentifierType.NNZAF}>SA ID</ToggleButton>
                            <ToggleButton value={IdentifierType.PPN}>Passport</ToggleButton>
                        </Field>
                    </Flex>
                    {/* ID Value */}
                    <StyledTextField
                        name={`${name}.identifier`}
                        label={identifier_type ?? IdentifierType.NNZAF}
                        theme={theme}
                        fieldProps={{
                            validate: identifier_type === IdentifierType.NNZAF ? requiredSAIdValidator(`Please provide a South African ID number`, `Invalid South African ID number`, 13) : requiredValidator(`Please provide a ${identifier_type}`)
                        }}
                    />
                    {/* Date of Birth */}
                    <StyledTextField
                        name={`${name}.date_of_birth`}
                        label="Date of birth"
                        placeholder={'YYYY-MM-DD'}
                        helperText={identifier_type === IdentifierType.NNZAF ? 'Auto-completed from patient SA ID' : 'Please complete if SA ID not available'}
                        disabled={identifier_type === IdentifierType.NNZAF}
                        data-autofilled={identifier_type === IdentifierType.NNZAF}
                        theme={theme}
                        fieldProps={{
                            validate: requiredDateValidator('Please provide a date of birth', 'Please use the format YYYY-MM-DD')
                        }}
                    />
                     {/*Medical Aid Scheme (Optional)*/}
                    <StyledAutoComplete
                        label={"Medical Aid Scheme (Optional)"}
                        name={`${name}.medical_aid_scheme`}
                        options={MEDICAL_AIDS}
                        theme={theme}
                        renderOptions={(params) => <StyledTextField label="Medical Aid Scheme" variant="outlined" {...params} />}
                    />
                     {/*Medical Aid Number (Required if medical aid scheme selected)*/}
                    <StyledTextField
                        name={`${name}.medical_aid_number`}
                        label={"Medical Aid Number (Optional)"}
                        disabled={!medical_aid_scheme}
                        theme={theme}
                    />
                    {/* Notes (Optional) */}
                    <StyledTextField
                        name={`${name}.notes`}
                        label={"Notes (Optional)"}
                        theme={theme}
                    />
                    <RemoveButton
                        type="button"
                        theme={theme}
                        onClick={(event) => {
                            event.preventDefault();
                            remove(index)
                        }}
                    >
                        Remove Patient
                    </RemoveButton>
                </Spacer>
            </AccordionDetails>
        </Accordion>
    )
}

export default PatientCaptureSection


const Spacer = styled.div({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    rowGap: '1rem',
    padding: '1rem 0 0.5rem',
    '& .MuiAutocomplete-root': {
        width: '100%'
    }
});

const Flex = styled.div({
    display: 'flex'
});

const Instruction = styled.div({
    padding: '1rem 1rem 0.5rem 0'
});
