import React, {useEffect, useState} from 'react';
import {Snackbar, Button, useTheme} from '@material-ui/core';
import * as serviceWorker from '../../serviceWorkerRegistration';
import styled from "styled-components";
import {useSelector} from 'react-redux';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const minimum_version = useSelector(state => state.application.minimum_version)
  const theme = useTheme();

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({onUpdate: onSWUpdate});
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({type: 'SKIP_WAITING'});
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <ReloadPrompt theme={theme} open={showReload} message={`Version ${minimum_version} is available!`} onClick={reloadPage}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}} action={
        <Button color="inherit" size="small" onClick={reloadPage}>Install now</Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;

const ReloadPrompt = styled(Snackbar)`
  .MuiSnackbarContent-root {
    background: ${({theme}) => theme.palette.primary.main};
    color: white;
    font-weight: 500;
  }
`