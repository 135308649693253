
const DEFAULT_STATE = {
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        default:
            return state;
    }
}
