import React from "react";
import styled, {keyframes} from "styled-components";
import MenuIcon from "@material-ui/icons/Menu";
import CloudOffTwoToneIcon from '@material-ui/icons/CloudOffTwoTone';
import CloudRoundedIcon from '@material-ui/icons/CloudRounded';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import {ReactComponent as NetcareLogo} from "../assets/netcareLogo.svg";
import {useTheme} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {reloadVehicleFromServer} from "../../redux/actions/vehicle";

const NavBar = ({title, status, menu, loading}) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    return (
        <Header theme={theme} justifyContent={status !== null ? 'space-between' : 'start'}>
            <LogoContainer>
                <NetcareLogo>{menu && <MenuIcon />}</NetcareLogo>
            </LogoContainer>
            <Title marginLeft={status !== null ? 0 : '20px'}>{title}</Title>
            {status !== null && <Icon status={status} theme={theme}>
                {status ? !loading ? <CloudRoundedIcon onClick={() => status ? dispatch(reloadVehicleFromServer()) : ''}/> : <StyledSyncIcon /> : <CloudOffTwoToneIcon />}
            </Icon>}
        </Header>
    );
};

export default NavBar;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({justifyContent}) => justifyContent};

    padding: 0.25rem 1rem;

    width: calc(100% - 2rem);
    height: calc(60px - 0.5rem);

    background: ${({theme}) => theme.palette.background.header};
`;

const Title = styled.div`
    height: min-content;
    display: flex;
    font-weight: bold;
    color: whitesmoke;
    margin-left: ${({marginLeft}) => marginLeft}
`;

const Icon = styled.div`
    display: flex;
    margin-left: 55px;
    font-weight: bold;
    color: ${({status, theme}) => (status === "Offline" ? theme.palette.error.main : "white")};
`;

const LogoContainer = styled.div`
    mix-blend-mode: luminosity;
    filter: grayscale(1);
`;

const syncKeyframes = keyframes`
    0% {transform: rotate(0deg)};
    100% {transform: rotate(360deg)};
`

const StyledSyncIcon = styled(SyncRoundedIcon)`
    animation-name: ${syncKeyframes};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: reverse; 
    animation-timing-function: ease-in-out;
`;