import React from 'react';
import {useTheme} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {changeCaseState} from "../../../redux/actions/vehicle";
import {setNavigationEnabled} from "../../../redux/actions/application";
import {useHistory} from "react-router-dom";
import {StyledButton} from "../../components/PatientInfo/styledPatientInfoFields";
import styled from "styled-components";

const CancellationReasons = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const reasons = useSelector(state => state.staticData.cancellationReasons.reasons);
    const activeCase = useSelector(state => state.vehicle.activeCase);

    const navToHome = () => {
        dispatch(setNavigationEnabled(true));
        history.replace("/");
    }

    const updateCaseState = (text) => {
        dispatch(changeCaseState(activeCase.case_number, activeCase.case_state, "Case complete", text, null));
        navToHome();
    };

    return <>
        <Instruction>Please choose your reason for cancelling</Instruction>
        {(reasons[activeCase.case_state]?.map((reason, index) => <StyledButton onClick={() => updateCaseState(reason)} theme={theme} key={index}>{reason}</StyledButton>))}</>
};

export default CancellationReasons;

const Instruction = styled.div`
  width: 100%;
  padding: 1rem 0 0.5rem;
`;
