import {
    AUTHENTICATION_ERROR,
    CASE_UPDATED,
    CLEAR_CASE_STATE_UPDATE,
    LINK_CELLPHONE_SUCCESS,
    REASSIGN_DEVICE_TO_VEHICLE,
    NEW_CASE,
    RESET_CELLPHONE_NUMBER,
    SET_ACTIVE_CASE,
    SET_AVAILABILITY,
    SET_CALL_SIGN,
    SET_CELLPHONE_NUMBER,
    SET_DEVICE_ID,
    SET_VEHICLE_STATUS,
    SUBMIT_CELLPHONE_FAIL,
    SUBMIT_CELLPHONE_SUCCESS,
    UPDATE_LOCAL_CASE_STATE,
    RELOAD_VEHICLE_FROM_SERVER
} from "../actions/vehicle";

import * as Sentry from "@sentry/browser";

const DEFAULT_STATE = {
    cellphoneNumber: null,
    callSign: null,
    availability: "Available",
    activeCase: null,
    vehicle_status_reason: "offline",
    caseStateUpdate: null,
    deviceId: null,
    authError: null,
    reloadingVehicle: false,
    cancelledCase: null
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case AUTHENTICATION_ERROR:
            return { ...state, authError: action.payload};
        case SET_CELLPHONE_NUMBER:
            return { ...state, cellphoneNumber: action.payload};
        case SET_DEVICE_ID:
            return { ...state, deviceId: action.payload};
        case SET_CALL_SIGN:
            return { ...state, callSign: action.payload};
        case SET_AVAILABILITY:
            return {
                ...state,
                availability: action.payload.vehicleAvailability,
                vehicle_status_reason: action.payload.availabilityReason
            };
        case RELOAD_VEHICLE_FROM_SERVER:
            return { ...state, reloadingVehicle: true }        
        case UPDATE_LOCAL_CASE_STATE:
            const newState = {...state}
            // if (!('time_state_entered' in newState['activeCase'])) {
            //     Sentry.captureMessage(`UPDATE_LOCAL_CASE_STATE failed to update time: ${JSON.stringify(newState)}, payload: ${JSON.stringify(action.payload)}`)
            // }
            newState["activeCase"]["time_state_entered"][action.payload.state] = action.payload.time;
            return {...newState}
        case SET_ACTIVE_CASE:
            return { ...state, activeCase: action.payload};
        case SUBMIT_CELLPHONE_FAIL:
            return { ...state, cellphoneNumber: "error"};
        case SUBMIT_CELLPHONE_SUCCESS:
            return { ...state, cellphoneNumber: action.payload};
        case RESET_CELLPHONE_NUMBER:
            return { ...state, cellphoneNumber: null};
        case LINK_CELLPHONE_SUCCESS:
            return { ...state, cellphoneNumber: action.payload.cellphone_number, callSign: action.payload.call_sign};
        case REASSIGN_DEVICE_TO_VEHICLE:
            return { ...state, cellphoneNumber: action.payload.cellphone_number, callSign: action.payload.call_sign};
        case SET_VEHICLE_STATUS:
            return {
                ...state,
                availability: action.payload.vehicle_status || state.availability,
                vehicle_status_reason: action.payload.vehicle_status_reason || state.vehicle_status_reason,
                activeCase: {...action.payload.case},
                reloadingVehicle: false,
                cancelledCase: action.payload.cancelled_case_number || null
            }
        case NEW_CASE:
            return {
                ...state,
                activeCase: {...action.payload},
                caseStateUpdate: "new"
            }
        case CASE_UPDATED:
            return {
                ...state,
                activeCase: {...action.payload},
                caseStateUpdate: "update"
            }
        case CLEAR_CASE_STATE_UPDATE:
            return {
                ...state,
                caseStateUpdate: null
            }
        default:
            return state;
    }
}
