import {useTheme} from "@material-ui/core";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import StatusButton from "../components/statusButton";
import styled from "styled-components";
import VersionFooter from "../components/VersionFooter";
import {deleteItemFromStorage, getItemFromStorage} from "../../redux/middleware/components/storage";
import {setCrew} from "../../redux/actions/crew";
import {setActiveCase} from "../../redux/actions/vehicle";
import {useHistory} from "react-router-dom";
import {setActiveTab} from "../../redux/actions/application";
import Card from "../components/card";

const SetVehicleStatus = ({vehicleStatus}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const availabilityReason = useSelector(state => state.vehicle.vehicle_status_reason);
    const hasCrew = useSelector(state => Object.keys(state.crew.crew).length > 0);
    const activeCase = useSelector(state => state.vehicle.activeCase);

    const statusButtons = [
        {
            color: theme.palette.success.main,
            reason: "available",
            availability: "Available"
        },
        {
            color: theme.palette.success.main,
            reason: "2nd Call",
            availability: "Available"
        },
        {
            color: theme.palette.error.main,
            reason: "maintenance",
            availability: "Unavailable"
        },
        {
            color: theme.palette.error.main,
            reason: "service",
            availability: "Unavailable"
        },
        {
            color: theme.palette.error.main,
            reason: "offline",
            availability: "Unavailable"
        },
    ];

    useEffect(() => {
        if (!hasCrew) {
            const crew = getItemFromStorage("crew");

            if (crew) {
                dispatch(setCrew(JSON.parse(crew)));
                deleteItemFromStorage("crew");
            }
        }
    }, [hasCrew]);

    useEffect(() => {
        if ((activeCase && Object.keys(activeCase).length == 0)) {
            const storedVehicle = getItemFromStorage("vehicle");
            if (storedVehicle) {
                const storedCase = JSON.parse(storedVehicle)?.activeCase;
                if (storedCase && Object.keys(storedCase).length) {
                    dispatch(setActiveCase(storedCase));
                    deleteItemFromStorage("vehicle");
                }
            }
        }
    }, [activeCase]);

    const navigateToClockIn = () => {
        history.replace(`/clock-in`);
        dispatch(setActiveTab("Crew"))
    }

    const navigateToHome = () => {
        history.replace(`/`);
        dispatch(setActiveTab("Home"))
    }

    const renderNoCrew = () => {
        return (<>
            <Card>
                <Center onClick={() => navigateToClockIn()}>
                    <Title theme={theme} type={"error"}>No rostered staff</Title>
                    <SubTitle>Please roster some crew members. Tap here to go to the roster page.</SubTitle>
                </Center>
            </Card>
            <VersionFooter />
        </>);
    };

    const renderActiveCase = () => {
        return (<>
            <Card>
                <Center onClick={() => navigateToHome()}>
                    <Title theme={theme} type={"warning"}>Vehicle currently dispatched</Title>
                    <SubTitle>Please complete the currently active case to change the vehicle's status</SubTitle>
                </Center>
            </Card>
            <VersionFooter />
        </>);
    }

    const renderStateOptions = () => {
        return (<>
            <Instruction>Select the current vehicle status</Instruction>
            {statusButtons.map((button, index) => (
                <StatusButton key={index} active={availabilityReason === button.reason} button={button}/>
            ))}
            <VersionFooter />
        </>);
    }

    if (!hasCrew)
        return renderNoCrew();
    else if (activeCase?.case_state)
        return renderActiveCase();
    else  
        return renderStateOptions();
};

export default SetVehicleStatus;

const Instruction = styled.div`
  color: rgba(255, 255, 255, 0.75);
  font-weight: 500;
  padding: 0.5rem 0 0;
  display: flex;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  display: flex;
  color: ${({theme, type}) => theme.palette[type || "normal"].main};
`;

const SubTitle = styled.div`
  text-align: center;
  display: flex;
`;