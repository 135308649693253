import React, {useEffect, useState} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField, useTheme} from "@material-ui/core";
import {addItemToStorage, getItemFromStorage} from "../../../redux/middleware/components/storage";
import {getDistance} from "../../../distanceUtils";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {SubmitButton} from "../../components/PatientInfo/styledPatientInfoFields";
import {setNavigationEnabled} from "../../../redux/actions/application";
import {useHistory} from "react-router-dom";

const sortFacilities = (facilities) => {
    try {
        let location = JSON.parse(getItemFromStorage("location_current"));
        let lat = location.coords.latitude;
        let lon = location.coords.longitude;
        return [...facilities].sort((a, b) => getDistance(lat, lon, a.lat, a.lon) - getDistance(lat, lon, b.lat, b.lon));
    } catch (e) { }

    return facilities
};

const FacilitiesSelect = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const facilities = useSelector(state => state.staticData.facilities.facilities);
    const [sortedFacilities, setSortedFacilities] = useState([...facilities]);
    const [currentFacility, setcurrentFacility] = useState(getItemFromStorage('currentFacility') || "");

    const navToHome = () => {
        dispatch(setNavigationEnabled(true));
        history.replace("/");
    }

    useEffect(() => {
        // TODO: Fix location ordering (Backlog)
        setSortedFacilities(sortFacilities(facilities));
    }, []);

    const saveFacility = (value) => {
        setcurrentFacility(value || "");
    };

    return (
        <>
            <Instruction>Please select the destination facility</Instruction>
            <Spacer>
                <Autocomplete id="facility-picker" value={currentFacility} options={sortedFacilities.map(item => item.facility)} onChange={(ev, value) => saveFacility(value)}
                    renderInput={(params) => <TextField {...params} label="Select Facility" variant="outlined"/>}/>
            </Spacer>
            <SubmitButton disabled={currentFacility === ""} onClick={() => {
                addItemToStorage('currentFacility', currentFacility)
                navToHome();
            }} theme={theme}>{`${currentFacility !== "" ? 'Confirm' : 'Select a'} Facility`}
            </SubmitButton>
        </>
    );
};

export default FacilitiesSelect;

const Instruction = styled.div`
  width: 100%;
  padding: 1rem 0 0.5rem;
`;

const Spacer = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0;

  .MuiAutocomplete-root {
    width: 100%;
  }
`;