import {combineReducers} from "redux";
import localStorage from "./storage";
import network from './network';
import ui from './ui';
import application from "./application";
import vehicle from "./vehicle";
import socket from "./socket";
import crew from "./crew";
import permissions from "./permissions";
import staticData from "./staticData";


const rootReducer = combineReducers({
    application,
    crew,
    localStorage,
    network,
    socket,
    staticData,
    ui,
    vehicle,
    permissions
});

export default rootReducer;
