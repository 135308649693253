import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {CASE_STATES} from '../../constants';
import {setNavigationEnabled} from "../../redux/actions/application";
import {changeCaseState} from '../../redux/actions/vehicle';

import { Form } from 'react-final-form'
import arrayMutators from "final-form-arrays";
import {focusOnError} from "../components/Utils/decorators";
import PatientCaptureForm from "../components/PatientInfo/patientCaptureForm";
import {deleteItemFromStorage, getItemFromStorage} from "../../redux/middleware/components/storage";
import * as Sentry from "@sentry/browser";


const validate = (formValues) => {
    return formValues?.patients?.length > 0;
};

const parseStoredJSON = (item, defaultValue=null) => {
    try {
        const result = JSON.parse(item)
        // if (!result) Sentry.captureMessage("The result of parseStoredJSON was null/undefined");
        return result;
    } catch (e) {
        Sentry.captureException(e);
    }
    return defaultValue;
}

const PatientInformation = ({formRef}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const activeCase = useSelector(state => state.vehicle.activeCase)

    const {case_number, ...patientInfoForm} = parseStoredJSON(getItemFromStorage('patient_info_form') || '{}', {}) || {};

    const navToHome = () => {
        dispatch(setNavigationEnabled(true));
        history.replace("/");
    }

    const updateCaseState = (patients) => {
        if (!CASE_STATES[activeCase?.case_state]) {
            const storedVehicle = getItemFromStorage('vehicle')
            // Sentry.captureMessage(`Update case error: no active case state, ${JSON.stringify(activeCase)}, stored_vehicle: ${storedVehicle}`);
        }

        dispatch(changeCaseState(
            activeCase.case_number,
            activeCase.case_state,
            CASE_STATES[activeCase.case_state].next_state,
            activeCase.cancellation_reason,
            {
                facility: activeCase.facility,
                lat: activeCase.destination?.lat,
                lon: activeCase.destination?.lon
            },
            patients
        ));
        deleteItemFromStorage('patient_info_form')
        navToHome();
    };

    return (
        <Form
            onSubmit={updateCaseState}
            mutators={{
              // potentially other mutators could be merged here
              ...arrayMutators
            }}
            component={PatientCaptureForm}
            validate={validate}
            decorators={[focusOnError]}
            formRef={formRef}
            // Fix assignment error for input(s) by passing {} rather than null
            initialValues={case_number === activeCase.case_number ? patientInfoForm : {}}
            caseNumber={activeCase.case_number}
        />
    );
};

export default PatientInformation;
