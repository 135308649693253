import React from "react";
import {useDispatch, useSelector} from "react-redux"
import styled from "styled-components";
import {useTheme} from "@material-ui/core";
import {setAvailability} from "../../redux/actions/vehicle";
import {setActiveTab} from "../../redux/actions/application";
import {useHistory} from "react-router-dom";


const StatusButton = ({button, active}) => {
    const {color, availability, reason} = button;
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const currAvailability = useSelector(state => state.vehicle.availability)
    const currStatusReason = useSelector(state => state.vehicle.vehicle_status_reason)

    const updateAvailability = () => {
        if (availability !== currAvailability || currStatusReason !== reason) {
            dispatch(setAvailability(availability, reason));
    
            setTimeout(() => { // Timeout so the UI update confirms the user action visually before changing tabs
                dispatch(setActiveTab("Home"));
                history.replace("/");
            }, 300);
        }
    }

    const showDetails = (availability, reason) => {
        return availability.toTitle() !== reason.toTitle();
    }

    return (
        <Container
            onClick={() => updateAvailability()}
            theme={theme}
            active={active}
            color={color}>
            {
                `${reason.toTitle()} ${!showDetails(availability, reason) ? '' : '(' + availability + ')'}`
            }
        </Container>
    );
};

export default StatusButton;

const Container = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: calc(100% - 2rem - 2px);
  height: calc(56px - 1rem);

  padding: 0.5rem 1rem;
  margin: 1rem 0;

  color: ${({active, color}) => (active ? color : "white")};
  background: ${({theme}) => theme.palette.background.darker};
  border-radius: 0.5rem;
  border: 1px solid ${({theme, active, color}) => (active ? color : theme.palette.background.border)};
`;
