import React, {useEffect} from "react";
import PatientCaptureSection from "./patientCaptureSection";
import styled from "styled-components";
import { useTheme } from "@material-ui/core";
import useFormStateRef from "../../hooks/useFormStateRef";

import { FieldArray } from 'react-final-form-arrays'
import {addItemToStorage} from "../../../redux/middleware/components/storage";
import {IdentifierType} from "../../../constants";
import {requiredValidator} from "../Utils/validators";
import {StyledButton, StyledTextField, SubmitButton} from "./styledPatientInfoFields";

const StyledSubmitButton = ({theme, invalid }) => {
    return (
        <SubmitButton disabled={invalid} theme={theme}>
            {invalid ? 'Complete details' : 'Submit'}
        </SubmitButton>
    )
}

const PatientCaptureForm = ({handleSubmit, values, dirty, form, formRef, caseNumber, ...props }) => {
    useFormStateRef({ formRef, form });
    const theme = useTheme();

    const [expanded, setExpanded] = React.useState(null);
    const changeExpanded = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    useEffect(() => {
        if (caseNumber){
            const patientFormToSave = JSON.stringify({...values, case_number: caseNumber});
            patientFormToSave && addItemToStorage('patient_info_form', patientFormToSave)
        }
    }, [values])

    return (
        <StyledForm
            onSubmit={handleSubmit}
        >
            <Instruction>Please complete patient basic information</Instruction>
            <FieldArray name={'patients'}>
                {({ fields = {} }) => {
                    {/* List of patients */}
                    return (<>
                    {
                        fields?.map((name, index) => <PatientCaptureSection
                            name={name}
                            index={index}
                            key={index}
                            theme={theme}
                            open={expanded}
                            setOpen={changeExpanded}
                            remove={(removeIndex) => {
                                setExpanded(null);
                                fields.remove(removeIndex)
                            }}
                        />)
                    }
                    {/* Add new patient (max 5) */}
                    <StyledButton
                        type="button"
                        theme={theme}
                        color={theme.palette.warning.main}
                        disabled={values?.patients?.length > 4}
                        onClick={(event) => {
                            event.preventDefault();
                            fields?.push({identifier_type: IdentifierType.NNZAF})
                            setExpanded(`patient${fields.length}`)
                        }}
                    >
                        Add Patient
                    </StyledButton></>)}}
            </FieldArray>
            {/* General notes */}
            <StyledTextField
                name={`patient_general_notes`}
                label="Patient general notes"
                theme={theme}
                fieldProps={{
                    validate: !values?.patients?.length ? requiredValidator('Please provide a note if no patient(s) information available') : undefined
                }}
            />
            <StyledSubmitButton theme={theme} invalid={!props.valid || (!values?.patients?.length && !values?.patient_general_notes)} />
        </StyledForm>
    )
}

export default PatientCaptureForm;

const Instruction = styled.div({
    width: '100%',
    padding: '1rem 0 0.5rem'
});

const StyledForm = styled.form({
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
});