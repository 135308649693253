import React from "react";
import styled from "styled-components";
import SignOff from "../components/signOff"
import Avatar from "../components/avatar";
import {useTheme} from "@material-ui/core";
import {useSelector} from "react-redux";

const Employee = ({ employee }) => {
    const theme = useTheme();
    const activeCase = useSelector(state => state.vehicle.activeCase);

    return (
        <Container theme={theme}>
            <Avatar fullName={`${employee.name} ${employee.surname}`}/>
            <Name>{employee.name} {employee.surname}</Name>
            {!activeCase?.case_state && <Action><SignOff employee={employee}/></Action>}
        </Container>
    );
};

export default Employee;

const Name = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 0 0 1rem;
    max-width: 52%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    width: calc(100% - 2rem);
    height: calc(56px - 1rem);

    padding: 0.5rem 1rem;
    margin: 1rem 0;

    border-radius: 0.5rem;
    color: white;
    background: ${({theme}) => theme.palette.background.darker};
`;

const Action = styled.div`
    display: flex;
    margin-left: auto;
`;
