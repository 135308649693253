import * as actions from "../action_types/action-types";
import _ from 'lodash';

const DEFAULT_STATE = {};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case actions.START_NETWORK:
            let requestCountStart = 1;

            if (action.payload in state) {
                requestCountStart += state[action.payload];
            }
            return { ...state, [action.payload]: requestCountStart};

        case actions.END_NETWORK:

            let requestCountEnd = -1;

            if (action.payload in state) {
                requestCountEnd += state[action.payload];
            }

            const updatedState = { ...state, [action.payload]: requestCountEnd};
            if (requestCountEnd <= 0) {
                return _.omit(updatedState, [action.payload]);
            }
            return updatedState;

        default:
            return state;
    }
}
