import {setLocationPermission} from "../../actions/permissions";
import {addItemToStorage} from "./storage";

const locationSupported = () => {
    return "geolocation" in navigator;
};

export const locationPermission = async () => {
    const permission = (await navigator.permissions.query({name: 'geolocation'}));
    return permission.state;
};

export const locationPermissionBlocked = async () => {
    const permission = (await navigator.permissions.query({name: 'geolocation'}));
    return permission.state === "denied";
};

export const askLocationPermission = (dispatch) => {
    navigator.permissions.query({name: "geolocation"})
    .then((permissionStatus) => {
        console.log(permissionStatus)
    })
    
    navigator.geolocation.getCurrentPosition(async () => {
        const permissionGranted = await locationPermission();
        dispatch(setLocationPermission(permissionGranted));
        addItemToStorage("permission_location", permissionGranted);
    }, (err) => {
        dispatch(setLocationPermission("denied"));
        addItemToStorage("permission_location", "denied");
    },
    {timeout: 30000, enableHighAccuracy: true, maximumAge: 75000});
};

export const startLocationWatch = async () => {
    const permissionGranted = await locationPermission();
    if (locationSupported() && permissionGranted === "granted") {
        navigator.geolocation.watchPosition((geoLocation) => {
            const locationJSON = {
                coords: {
                    accuracy: geoLocation.coords.accuracy,
                    altitude: geoLocation.coords.altitude,
                    altitudeAccuracy: geoLocation.coords.altitudeAccuracy,
                    heading: geoLocation.coords.heading,
                    latitude: geoLocation.coords.latitude,
                    longitude: geoLocation.coords.longitude,
                    speed: geoLocation.coords.speed,
                },
                timestamp: geoLocation.timestamp,
            };

            addItemToStorage("location_current", JSON.stringify(locationJSON));
        });
    }
};