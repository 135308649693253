import {
    APPLICATION_STARTED,
    GET_SOCKET_TOKEN_SUCCESS,
    GET_VEHICLE_STATUS_SUCCESS,
    getVehicleStatus,
    SET_AUTHORIZATION_TOKEN,
    SET_SHOULD_UPDATE,
    SET_SUBSCRIPTION_FAIL,
    SET_SUBSCRIPTION_SUCCESS,
    setAppVersion,
    setAuthorization,
    setAuthorizationToken,
    setShouldUpdate,
    subcribeToNotifications,
    UPDATE_APP
} from "../../actions/application";
import { addItemToStorage, getItemFromStorage } from "../components/storage";
import { setCallSign, setCellphoneNumber, setDeviceId, setVehicleStatus } from "../../actions/vehicle";
import { disconnectSocket, MESSAGE_RECEIVED, requestSocketConnect, setMessageQueue } from "../../actions/socket";
import { setCrew } from "../../actions/crew";
import { startLocationWatch } from "../components/geoLocation";

let stateGetter;
let dispatcher;

const MAX_RETRIES = 50;
let retryCount = 0;

const visibilityListener = () => {
    switch (document.visibilityState) {
        case "hidden": {
            let crew = stateGetter().crew.crew;
            let vehicle = stateGetter().vehicle;
            let messageQueue = stateGetter().socket.messageQueue;

            addItemToStorage('crew', JSON.stringify(crew));
            addItemToStorage('vehicle', JSON.stringify(vehicle));
            addItemToStorage('message_queue', JSON.stringify(messageQueue));
            dispatcher(disconnectSocket())
            break;
        }
        case "visible": {
            let crew = getItemFromStorage('crew');
            let vehicle = getItemFromStorage('vehicle');
            let messageQueue = getItemFromStorage('message_queue');

            if (vehicle) {
                const parsedVehicle = JSON.parse(vehicle)
                const payload = {
                    ...parsedVehicle,
                    case: structuredClone(parsedVehicle.activeCase),
                    vehicle_status: structuredClone(parsedVehicle.availability),
                    cancelled_case_number: structuredClone(parsedVehicle.cancelledCase)
                }

                const keysToDelete = ['activeCase', 'availability', 'cancelledCase']
                keysToDelete.forEach((key) => delete payload[key])

                if (crew) payload['crew'] = JSON.parse(crew)

                dispatcher(setVehicleStatus(payload));
            } else if (crew) {
                dispatcher(setCrew(JSON.parse(crew)));
            }

            if (messageQueue) {
                dispatcher(setMessageQueue(JSON.parse(messageQueue)))
            }

            let token = getItemFromStorage('auth_token');
            if (token) {
                dispatcher(setAuthorizationToken(token));
                dispatcher(setAuthorization(true));
            }
            break;
        }
        default:
            break;
    }
};

const updateServiceWorker = async () => {
    const serviceWorker = await navigator.serviceWorker.ready;
    await serviceWorker.update();
}


const applicationMiddleware = ({ dispatch, getState }) => next => action => {
    next(action);

    switch (action.type) {
        case APPLICATION_STARTED: {
            stateGetter = getState;
            dispatcher = dispatch;

            document.addEventListener("visibilitychange", visibilityListener);

            startLocationWatch(dispatch);

            const authToken = getItemFromStorage("auth_token");
            const cellphoneNumber = getItemFromStorage("cellphone_number");
            const connected = stateGetter().socket.connected;

            if (authToken && cellphoneNumber && !connected) {
                dispatch(getVehicleStatus(cellphoneNumber, authToken));
            }

            let keysToFetch = {
                "auth_token": true,
                "vehicle": true,
                "crew": true,
                "cellphone_number": setCellphoneNumber,
                "call_sign": setCallSign,
                "deviceId": setDeviceId
            };
            Object.keys(keysToFetch).forEach((key) => {
                let item = getItemFromStorage(key);
                if (item) {
                    if (key === 'vehicle') {
                        const parsedVehicle = JSON.parse(item) ?? {};
                        const payload = {
                            ...parsedVehicle,
                            case: structuredClone(parsedVehicle.activeCase),
                            vehicle_status: structuredClone(parsedVehicle.availability),
                            cancelled_case_number: structuredClone(parsedVehicle.cancelledCase)
                        }
                        dispatch(setVehicleStatus(payload));
                    }
                    else if (key === 'crew') dispatch(setCrew(JSON.parse(item)));
                    else if (key === 'auth_token') {
                            dispatch(setAuthorizationToken(item));
                            dispatch(setAuthorization(true));
                    }
                    else dispatch(keysToFetch[key](item));
                }
            });

            break;
        }
        case GET_VEHICLE_STATUS_SUCCESS: {
            /* server responds with incorrect quote type (' rather than ") */
            if (typeof action.payload === 'string') {
                // TODO: Remove this code once all devices have updated to ^0.2.0
                dispatch(setVehicleStatus(JSON.parse(action.payload.replace(/'/g, '"'))))
                break;
            }
            dispatch(setVehicleStatus(action.payload))
            break;
        }
        case SET_AUTHORIZATION_TOKEN: {
            dispatch(requestSocketConnect(action.payload));
            break;
        }
        case GET_SOCKET_TOKEN_SUCCESS: {
            dispatch(requestSocketConnect(action.payload.token));
            break;
        }
        case SET_SHOULD_UPDATE:
            if (action.payload) {
                dispatch(setAppVersion(action.payload.version))
                updateServiceWorker();
            }
            break;
        case UPDATE_APP: {
            dispatch(setShouldUpdate(true, action.payload));
            break;
        }
        case MESSAGE_RECEIVED: {
            let message = action.message;
            if (message.type) dispatch(message);
            break;
        }
        case SET_SUBSCRIPTION_SUCCESS: {
            const notificationSubscription = getState().application.notificationSubscription;

            if (notificationSubscription) {
                addItemToStorage("push_subscription", true);
            }
            break;
        }
        case SET_SUBSCRIPTION_FAIL: {
            setTimeout(() => {
                const subscription = getItemFromStorage("push_subscription");

                if (retryCount < MAX_RETRIES) {
                    retryCount++;
                    dispatch(subcribeToNotifications(
                        subscription,
                        getItemFromStorage("auth_token"),
                        action.payload.UUID
                    ));
                } else {
                    retryCount = 0;
                }
            }, 5000);
            break;
        }
        default:
            break;
    }
};

export default applicationMiddleware;