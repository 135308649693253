import React from 'react';
import './App.css';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './theme';
import { BrowserRouter as Router } from "react-router-dom";
import {Provider} from 'react-redux';
import store from "../redux/store";
import AuthenticationManager from "./views/authenticationManager";
import ServiceWorkerWrapper from './components/serviceWorkerWrapper';
String.prototype.toTitle = function() {
  return this.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
}

function App() {

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Router>
                    <AuthenticationManager />
                    <ServiceWorkerWrapper/>
                </Router>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
