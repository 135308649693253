import {applyMiddleware, compose, createStore} from 'redux';
import reducer from './reducers';
import thunkMiddleware from 'redux-thunk';
import api from './middleware/core/api';
import storage from './middleware/core/storage';
import applicationMiddleware from './middleware/feature/application';
import socketMiddleware from './middleware/feature/socket';
import vehicleMiddleware from "./middleware/feature/vehicle";
import crewMiddleware from "./middleware/feature/crew";
import permissionsMiddleware from './middleware/feature/permissions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(
    thunkMiddleware,
    crewMiddleware,
    storage,
    api,
    applicationMiddleware,
    socketMiddleware,
    vehicleMiddleware,
    permissionsMiddleware,
)));

window.store = store;

export default store;
