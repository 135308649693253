import Socket from '../components/Socket';
import {
    ADD_MESSAGE_TO_QUEUE,
    addMessageToQueue,
    CONNECT_SOCKET,
    connectionChanged,
    connectSocket,
    DISCONNECT_SOCKET,
    disconnectSocket,
    messageReceived,
    REMOVE_MESSAGE_FROM_QUEUE,
    removeMessageFromQueue,
    REQUEST_ACKNOWLEDGED,
    REQUEST_SOCKET_CONNECT,
    REQUEST_SOCKET_DISCONNECT,
    requestSocketConnect,
    RESEND_QUEUED_MESSAGES,
    SEND_MESSAGE_REQUEST,
    setMessageQueue
} from '../../actions/socket';
import {generateUUID} from '../components/uuid';


const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_ENDPOINT;

const socketMiddleware = (store) => {
    let token = '';
    let timeout = 125;

    const onConnectionChange = (isConnected) => {
        store.dispatch(connectionChanged(isConnected))
        if (isConnected)
            timeout = 125;
    };
    const onIncomingMessage = (message) => store.dispatch(messageReceived(message));

    const connectToSocket = () => {
        const shouldReconnect = store.getState().socket.shouldConnect && !store.getState().socket.connected;
        if (shouldReconnect) {
            store.dispatch(requestSocketConnect(store.getState().application.authorizationToken));
        }
    }

    const onDisconnect = () => setTimeout(connectToSocket, Math.min(5000,timeout+=timeout));
    const onError = () => {
        console.log("An error has occurred!")
        setTimeout(connectToSocket, Math.min(5000, timeout += timeout))
    };


    const socket = new Socket(onConnectionChange, onIncomingMessage, onDisconnect, onError);


    return (next) => (action) => {
        switch (action.type) {
            case ADD_MESSAGE_TO_QUEUE: {
                const messageQueue = JSON.parse(JSON.stringify(store.getState().socket.messageQueue || {}));
                const UUID = action.payload.message.kwargs.request_id;

                if (UUID) {
                    messageQueue[`${UUID}`] = action.payload;
                    store.dispatch(setMessageQueue(messageQueue));
                }
                break;
            }
            case REMOVE_MESSAGE_FROM_QUEUE: {
                const messageQueue = JSON.parse(JSON.stringify(store.getState().socket.messageQueue || {}));
                const UUID = action.payload;
                delete messageQueue[`${UUID}`];
                store.dispatch(setMessageQueue(messageQueue));
                break;
            }
            case RESEND_QUEUED_MESSAGES:
                const messageQueue = JSON.parse(JSON.stringify(store.getState().socket.messageQueue || {}));
                Object.keys(messageQueue).forEach(key => {
                    store.dispatch(removeMessageFromQueue(key))
                    const newMessage = messageQueue[key];
                    newMessage.message.kwargs.request_id = generateUUID();
                    store.dispatch(newMessage)
                });
                break;
            case REQUEST_SOCKET_CONNECT:
                token = action.payload;
                if (store.getState().socket.connected)
                    return;
                if (!!token)
                    return store.dispatch(connectSocket(token));
                break;
            case REQUEST_SOCKET_DISCONNECT:
                store.dispatch(disconnectSocket());
                break;
            case CONNECT_SOCKET:
                if (!!action.payload)
                    return socket.connect(`${WEBSOCKET_URL}?token=${action.payload}`);
                break;
            case DISCONNECT_SOCKET:
                socket.disconnect();
                break;
            case REQUEST_ACKNOWLEDGED: {
                store.dispatch(removeMessageFromQueue(action.payload));
                break;
            }
            case SEND_MESSAGE_REQUEST: {
                let message = {...action.message};

                if (Object.keys(message).length > 0) {
                    message['action'] = 'rpc';
                    message['kwargs']['token'] = store.getState().application.authorizationToken;
                    socket.sendMessage(message);
                    if (message['kwargs']['request_id']) {
                        store.dispatch(addMessageToQueue({
                            type: SEND_MESSAGE_REQUEST,
                            message
                        }));
                    }

                }
                break;
            }
            default:
                break;
        }

        return next(action);
    };
};

export default socketMiddleware;
