import {subcribeToNotifications} from "../../actions/application";
import {setNotificationPermission} from "../../actions/permissions";
import {addItemToStorage, getItemFromStorage} from "./storage";
import {generateUUID} from "./uuid";

const urlB64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};

const notificationsSupported = () => {
    return "serviceWorker" in navigator && "PushManager" in window;
};

export const notificationPermission = () => {
    return Notification.permission;
};

export const notificationPermissionBlocked = () => {
    return Notification.permission === "denied";
};

export const askNotificationPermission = async (dispatch) => {
    const isRunningInApp = getItemFromStorage("runningInApp") === "true";

    if (!isRunningInApp) {
        const permission = await Notification.requestPermission();
        dispatch(setNotificationPermission(permission));
    }
};

const createSubscription = async () => {
    const serviceWorker = await navigator.serviceWorker.ready;
    const subscription = await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
    });

    return subscription;
};

export const subscribeNotifications = async (dispatch) => {
    if (notificationsSupported() && notificationPermission() === "granted") {
        const subscription = await createSubscription();
        const actionWithId = subcribeToNotifications(subscription, getItemFromStorage("auth_token"), generateUUID());
        addItemToStorage("push_subscription", JSON.stringify(subscription));
        dispatch(actionWithId);
    }
};

export const initializePushNotifications = async (dispatch) => {
    const pushNotificationsBC = new BroadcastChannel("push-notifications");

    pushNotificationsBC.onmessage = (event) => {
        const options = {
            body: event.data.message,
            icon: "/favicon.ico",
            data: event.data
        };

        const notification = new Notification(event.data.title, options);
        notification.onclick = (event) => {
            window.open(event.data.url);
        };
    };
};