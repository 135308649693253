export const SET_EMPLOYEE_NUMBER = "SET_EMPLOYEE_NUMBER";
export const SUBMIT_EMPLOYEE_NUMBER = "SUBMIT_EMPLOYEE_NUMBER";
export const SUBMIT_EMPLOYEE_SUCCESS = "SUBMIT_EMPLOYEE_SUCCESS";
export const SUBMIT_EMPLOYEE_FAIL = "SUBMIT_EMPLOYEE_FAIL";
export const RESET_EMPLOYEE_NUMBER = "RESET_EMPLOYEE_NUMBER";
export const REQUEST_CLOCK_IN_EMPLOYEE = "REQUEST_CLOCK_IN_EMPLOYEE";
export const CLOCK_IN_EMPLOYEE = "CLOCK_IN_EMPLOYEE";
export const EMPLOYEE_CLOCK_IN_RESPONSE_FAILED = "EMPLOYEE_CLOCK_IN_RESPONSE_FAILED";
export const EMPLOYEE_CLOCK_IN_RESPONSE_SUCCESS = "EMPLOYEE_CLOCK_IN_RESPONSE_SUCCESS";
export const EMPLOYEE_CLOCK_IN_REQUEST_FAILED = "EMPLOYEE_CLOCK_IN_REQUEST_FAILED";
export const EMPLOYEE_CLOCK_IN_REQUEST_SUCCESS = "EMPLOYEE_CLOCK_IN_REQUEST_SUCCESS";
export const CLOCK_OUT_EMPLOYEE = "CLOCK_OUT_EMPLOYEE";
export const EMPLOYEE_CLOCK_OUT_REQUEST_SUCCESS = "EMPLOYEE_CLOCK_OUT_REQUEST_SUCCESS";
export const EMPLOYEE_CLOCK_OUT_REQUEST_FAILED = "EMPLOYEE_CLOCK_OUT_REQUEST_FAILED";
export const SET_CREW = "SET_CREW";

export const submitEmployeeNumber = (employeeNumber) => ({
    type: SUBMIT_EMPLOYEE_NUMBER,
    payload: employeeNumber
})


export const employeeFail = (employeeNumber) => ({
    type: SUBMIT_EMPLOYEE_FAIL,
    payload: employeeNumber
})

export const resetEmployee = (employeeNumber) => ({
    type: RESET_EMPLOYEE_NUMBER,
    payload: null
})

export const setEmployeeNumber = (employeeNumber) => ({
    type: SET_EMPLOYEE_NUMBER,
    payload: employeeNumber
})

export const requestClockInEmployee = (employee_number) => ({
    type: REQUEST_CLOCK_IN_EMPLOYEE,
    payload: employee_number
});

export const clockInEmployee = (employee_number) => ({
    type: CLOCK_IN_EMPLOYEE,
    payload: employee_number
});


export const clockOutEmployee = (employee_number) => ({
    type: CLOCK_OUT_EMPLOYEE,
    payload: employee_number
});

export const setCrew = (crew) => ({
    type: SET_CREW,
    payload: crew
})
