import {useTheme} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from 'react-router-dom';
import styled from "styled-components";
import {CASE_STATES, FACILITIES} from '../../constants';
import {setActiveTab} from '../../redux/actions/application';
import {setCrew} from '../../redux/actions/crew';
import {
    acknowledgeAutoTransition,
    changeCaseState,
    clearCancelledCase,
    setActiveCase,
    updateDeviceId,
    updateLocalCaseState
} from '../../redux/actions/vehicle';
import {deleteItemFromStorage, getItemFromStorage} from '../../redux/middleware/components/storage';
import Card from '../components/card';
import CaseInformation from '../components/caseInformation';
import DispatchStatus from "../components/dispatchStatus";
import SwipeableButton from '../components/swipeableButton';
import WSConnectionBanner from "../components/WSConnectionBanner";
import AutoTransitionCancelBanner from "./components/AutoTransitionCancelBanner";
import AutoTransitionCancelButton from "./components/AutoTransitionCancelButton";
import {useAutoTransition} from "./hooks/useAutoTransition";
import AutoTransitionedMessage from "./components/AutoTransitionedMessage";

const Overview = () => {
    const WSBannerTitle = "Device offline";
    const WSBannerSubtitle = "Contact EOC Manually for updates";
    const WSBannerStatus = "error"; // "error" | "warning" | "normal" | "success"
    const vehicleBannerStatus = useSelector(state => state.vehicle.vehicle_status_reason);
    const websocketConnected = useSelector(state => state.socket.connected);
    const activeCase = useSelector(state => state.vehicle.activeCase);
    const callSign = useSelector(state => state.vehicle.callSign);
    const cancelledCaseNumber = useSelector(state => state.vehicle.cancelledCase);
    const stateBeforeAutoTransition = useSelector(state => state.vehicle.activeCase?.state_before_auto_transition);
    const hasCrew = useSelector(state => Object.keys(state.crew.crew).length > 0);
    const dispatch = useDispatch();
    const theme = useTheme();
    const { deviceId } = useParams();
    const storedDeviceId = useSelector(state => state.vehicle.deviceId);

    const {timeRemaining: autoTransitionTimeRemaining, visible: autoTransitionVisible} = useAutoTransition();

    const history = useHistory();
    const hospitalSelectState = "At incident";
    const captureInfoState = "Awaiting handover";
    const completeCaseState = "Capturing patient info";

    let caseStateChangeDelay = null;

    const currentCaseState = activeCase?.case_state;
    const currentFacility = getItemFromStorage('currentFacility');

    useEffect(() => {
        if (!hasCrew) {
            const crew = getItemFromStorage("crew");

            if (crew) {
                dispatch(setCrew(JSON.parse(crew)));
                deleteItemFromStorage("crew");
            }
        }
    }, [hasCrew])

    useEffect(() => {
        if ((activeCase && Object.keys(activeCase).length === 0)) {
            const storedVehicle = getItemFromStorage("vehicle");
            if (storedVehicle) {
                const storedCase = JSON.parse(storedVehicle)?.activeCase;
                if (storedCase && Object.keys(storedCase).length) {
                    dispatch(setActiveCase(storedCase));
                    deleteItemFromStorage("vehicle");
                }
            }
        }
    }, [activeCase])

    useEffect(() => {
        if (deviceId && deviceId !== storedDeviceId) {
            dispatch(updateDeviceId(deviceId))
        }
    }, [storedDeviceId, deviceId])

    useEffect(() => {
        if (activeCase?.case_state) {
            dispatch(setActiveCase(activeCase));
        }
        return () => {
            clearTimeout(caseStateChangeDelay);
        };
    }, []);

    const NextCaseState = () => {
        caseStateChangeDelay = setTimeout(() => {
            const currentState = CASE_STATES[activeCase?.case_state].current_state;
            const nextState = CASE_STATES[activeCase?.case_state].next_state;
            const detailedFacility = FACILITIES.find((facility => facility.facility === currentFacility))

            dispatch(changeCaseState(activeCase.case_number, currentState, nextState, null, detailedFacility));
            dispatch(updateLocalCaseState(nextState, (new Date()).toISOString()))

            if (currentFacility) deleteItemFromStorage('currentFacility')

            if (nextState === completeCaseState) {
                history.replace(`/capture/patient-information`);
            }
        }, 1000);
    };

    const CancelCase = () => {
        caseStateChangeDelay = setTimeout(() => {
            history.replace(`/capture/cancel-reason`);
        }, 1000);
    };

    const hasActiveCase = () => {
        return activeCase && Object.keys(activeCase).length > 0;
    };

    const renderSwipeRightButton = () => {
        return (
            <SwipeableButton
                onComplete={() => NextCaseState()}
                label={CASE_STATES[activeCase?.case_state]?.before_label}
                activeLabel={CASE_STATES[activeCase?.case_state]?.after_label}
                direction={"right"}/>
        );
    };

    const renderSwipeLeftButton = () => {
        if (["New case", "At hospital"].includes(currentCaseState)) return null;

        return (
            <SwipeableButton
                onComplete={() => {
                    CancelCase()
                    deleteItemFromStorage('currentFacility')
                }} label={"Cancel case"}
                activeLabel={"Cancelled"}
                direction={"left"}/>
        );
    };

    const renderHospitalSelectStateButton = () => {
        return (
            <SwipeableButton
                onComplete={() => history.replace(`/capture/facility`)}
                label={"Select Facility"}
                activeLabel={"Selecting Facility.."}
                direction={"right"}/>
        );
    }

    const renderCaptureInfoStateButton = () => {
        return (
            <SwipeableButton
                onComplete={() => history.replace(`/capture/patient-information`)}
                label={"Capture patient info"}
                activeLabel={"Capturing info..."}
                direction={"right"}/>
        );
    }

    const renderCompleteCaseStateButton = () => {
        return (
            <SwipeableButton
                onComplete={() => {
                    dispatch(changeCaseState(activeCase.case_number, activeCase?.case_state, "Case complete"));
                }}
                label={"Complete case"}
                activeLabel={"Case completed"}
                direction={"right"}/>
        );
    }

    const renderAutoTransitionedAcknowledge = () => {
        return (
            <SwipeableButton
                onComplete={() => {
                    caseStateChangeDelay = setTimeout(() => {
                        dispatch(acknowledgeAutoTransition({
                            case_number: activeCase.case_number
                        }))
                    }, 1000)
                }}
                label={"Acknowledge transition"}
                activeLabel={"Acknowledged"}
                type={'warning'}
                direction={"left"}/>
        );
    }

    const renderCaseCancelledAcknowledgeButton = () => {
        return (
            <SwipeableButton
                onComplete={() => {
                    caseStateChangeDelay = setTimeout(() => {
                        dispatch(clearCancelledCase())
                        deleteItemFromStorage('currentFacility') // If not remove, will be present for next case
                    }, 1000)
                }}
                label={"Acknowledge cancellation"}
                activeLabel={"Acknowledged"}
                type={'error'}
                direction={"left"}/>
        );
    }

    const renderSwipeableButtons = () => {
        if (cancelledCaseNumber) return <SwipeContainer>{renderCaseCancelledAcknowledgeButton()}</SwipeContainer>;
        if (stateBeforeAutoTransition) return <SwipeContainer>{renderAutoTransitionedAcknowledge()}</SwipeContainer>;
        if (autoTransitionVisible) return <SwipeContainer>{<AutoTransitionCancelButton
            timeRemaining={autoTransitionTimeRemaining}
            activeCase={activeCase}
        />}</SwipeContainer>;
        if (!hasActiveCase()) return null;
        if (currentCaseState === captureInfoState) return <SwipeContainer>{renderCaptureInfoStateButton()}</SwipeContainer>;
        if (currentCaseState === completeCaseState) return <SwipeContainer>{renderCompleteCaseStateButton()}</SwipeContainer>;
        if (currentCaseState === hospitalSelectState && !currentFacility) {
            return <SwipeContainer>
                {renderHospitalSelectStateButton()}
                {renderSwipeLeftButton()}
            </SwipeContainer>;
        }
        if (!hasCrew) return null;

        return (
            <SwipeContainer>
                {renderSwipeRightButton()}
                {renderSwipeLeftButton()}
            </SwipeContainer>
        );
    };

    const navigateToClockIn = () => {
        history.replace(`/clock-in`);
        dispatch(setActiveTab("Crew"))
    }

    const navigateToVehicleStatus = () => {
        history.replace(`/set-vehicle-status`);
        dispatch(setActiveTab("Vehicle"))
    }

    const renderNoCrewMessage = () => {
        if (hasCrew) return null;
        return (
            <Card>
                <Center onClick={() => navigateToClockIn()}>
                    <Title theme={theme} type={"error"}>No rostered staff</Title>
                    <SubTitle>Please roster some crew members. Tap here to go to the roster page.</SubTitle>
                </Center>
            </Card>);
    };

    const renderHasCaseNoCrewMessage = () => {
        if (hasCrew) return null;
        if (hasActiveCase())
            return (
                <Card>
                    <Center>
                        <Title theme={theme} type={"error"}>Case assigned</Title>
                        <SubTitle> Please clock in to accept the case</SubTitle>
                    </Center>
                </Card>);
        return null;
    };

    const renderNoCaseMessage = () => {
        if (!hasActiveCase())
            return (
                <Card>
                    <Center>
                        <Title theme={theme} type={"warning"}>No active case</Title>
                        <SubTitle>You currently do not have an active case!</SubTitle>
                    </Center>
                </Card>);
        return null;
    };

    const renderVehicleOfflineMessage = () => {
        if (vehicleBannerStatus === "offline" && hasCrew)
            return (
                <Card>
                    <Center onClick={() => navigateToVehicleStatus()}>
                        <Title theme={theme} type={"error"}>Vehicle is offline</Title>
                        <SubTitle>Please set the vehicle as available. Tap here to go to the vehicle status
                            page.</SubTitle>
                    </Center>
                </Card>);
        return null;
    };

    const renderShowCaseCancelled = () => {
        if (!cancelledCaseNumber) return false;

        return (
            <Card>
                <Center>
                    <Title theme={theme} type={"error"}>Previous case was cancelled</Title>
                    <SubTitle>
                        Previous case <b>{cancelledCaseNumber}</b> assigned to
                        <b>{callSign}</b> was cancelled by IDS.
                        Please <b>acknowledge</b> the cancellation before accepting new cases.
                    </SubTitle>
                </Center>
            </Card>
        )
    }

    return (
        <Container>
            {
                !websocketConnected &&
                <WSConnectionBanner
                    status={WSBannerStatus}
                    title={WSBannerTitle}
                    subtitle={WSBannerSubtitle}>
                </WSConnectionBanner>
            }

            {hasActiveCase() && !cancelledCaseNumber && <CaseInformation
                status={websocketConnected}
                activeCase={activeCase}/>}


            {renderSwipeableButtons()}
            {renderHasCaseNoCrewMessage()}
            {renderNoCaseMessage()}
            {renderNoCrewMessage()}
            {renderShowCaseCancelled()}
            <AutoTransitionedMessage stateBefore={stateBeforeAutoTransition} hasActiveCase={hasActiveCase} />
            {autoTransitionVisible && <AutoTransitionCancelBanner timeRemaining={autoTransitionTimeRemaining} activeCase={activeCase}/>}
            {renderVehicleOfflineMessage()}

            {hasCrew && <DispatchStatus
                position={"bottom"}
                status={vehicleBannerStatus.toTitle()}
            />}
        </Container>
    );
};

export default Overview;

const Container = styled.div`
  height: 100%;
  margin-bottom: 5rem;
`;

const SwipeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  position: absolute;
  bottom: calc(60px + 56px + 1.5rem);
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  display: flex;
  color: ${({ theme, type }) => theme.palette[type || "normal"].main};
`;

const SubTitle = styled.div`
  text-align: center;
`;
