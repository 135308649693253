import {red, lightGreen, lightBlue, orange, blueGrey} from '@material-ui/core/colors';
import {createTheme} from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: lightBlue.A700,
    },
    secondary: {
      main: lightBlue.A400,
      lighter: blueGrey.A400,
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: orange.A400,
    },
    success: {
      main: lightGreen.A700,
    },
    background: {
      header: '#292d42',
      default: '#1f1d2b',
      lighter: '#322f48',
      darker: '#141221',
      border: '#404a54',
      subtitle: '#778899'
    },
    myERA: {
      green: '#27AE60'
    }
  },
});

export default theme;