import React from "react";

export const renderPatientName = (index, firstName, lastName) => {
    return (<div style={{flexDirection: 'column'}}>
        <div style={{color: 'rgba(255,255,255,0.7)', fontSize: '0.9'}}>Patient {index + 1}</div>
        {(firstName || lastName) && <div style={{color: 'white'}}>{[firstName, lastName].join(" ")}</div>}
    </div>)
}

export const dateFromValidSAID = (identifier = '') => {
    if (identifier.length < 6) return null;
    if (!/^\d+$/.test(identifier.substring(0,6))) return null

    const year = parseInt(identifier.substring(0,2));
    const month = parseInt(identifier.substring(2,4));
    const date = parseInt(identifier.substring(4,6));

    if (month < 1 || month > 12) return null;
    if (date < 1 || date > 31) return null;


    const centuryDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100))

    let dateOfBirth = new Date(year, month - 1, date);
    if (dateOfBirth.getTime() < centuryDate.getTime())
        dateOfBirth = new Date(dateOfBirth.setFullYear(dateOfBirth.getFullYear() + 100))

    // Hacky: Update timezone to RSA (UTC+02:00) to correctly calculate date)
    dateOfBirth = new Date((new Date(dateOfBirth.setHours(2))).setUTCHours(2))

    return dateOfBirth?.toISOString().slice(0,10).replaceAll('/','-')
}
