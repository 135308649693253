const SOCKET_CONNECTING = 0;
const SOCKET_OPEN = 1;

export default class Socket {

    constructor(onChange, onMessage, onDisconnect, onError) {
        this.onChange = onChange;
        this.onMessage = onMessage;
        this.onDisconnect = onDisconnect;
        this.onError = onError;
        this.socket = null;
    }

    connect = (url) => {
        // Do not connect if app is not added to home screen

        if (!window.matchMedia('(display-mode: standalone)').matches) {
            console.log("not in standalone mode, not connecting")
            return;
        };

        if (this.socket && (this.socket.readyState === SOCKET_OPEN || this.socket.readyState === SOCKET_CONNECTING)) {
            console.log("Not connecting since connection in progress")
            return;
        }

        this.socket = new WebSocket(url);
        const onError = (event) => {
            this.onChange(false);
            this.onError();
        };

        this.socket.addEventListener('error', onError);

        this.socket.onopen = (event) => {
            this.onChange(true);
        };

        this.socket.onclose = (event) => {
            this.socket.removeEventListener('error', onError);
            this.onChange(false);
            this.onDisconnect();
        };


        this.socket.onmessage = (event) => {
            this.onMessage(JSON.parse(event.data));
        };

    };


    sendMessage = (message) => {

        if (this.socket && this.socket.readyState === SOCKET_OPEN) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.error('Cannot emit socket messages. WebSocket not connected.');
        }

    };

    disconnect = () => this.socket?.close();
}
