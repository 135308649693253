import {SET_LOCATION_PERMISSION, SET_NOTIFICATION_PERMISSION} from "../actions/permissions";

const DEFAULT_STATE = {
    locationPermission: false,
    notificationPermission: false,
};

export default function(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case SET_LOCATION_PERMISSION:
            return { ...state, locationPermission: action.payload};
        case SET_NOTIFICATION_PERMISSION:
            return { ...state, notificationPermission: action.payload};
        default:
            return state;
    }
}