import {useTheme} from "@material-ui/core";
import Card from "../../components/card";
import React from "react";
import {Center, Title, SubTitle} from "./styledOverviewComponents";

const AutoTransitionedMessage = ({hasActiveCase, stateBefore}) => {
    const theme = useTheme();

    if (!hasActiveCase() || !stateBefore) return null;

    return (
        <Card>
            <Center>
                <Title theme={theme} type={"warning"}>Case auto transitioned</Title>
                <SubTitle>Your current case has been auto transitioned based on your location.</SubTitle>
            </Center>
        </Card>
    );
};

export default AutoTransitionedMessage
