import {CLOCK_IN_EMPLOYEE, CLOCK_OUT_EMPLOYEE, EMPLOYEE_CLOCK_OUT_REQUEST_SUCCESS, REQUEST_CLOCK_IN_EMPLOYEE} from "../../actions/crew";
import {SEND_MESSAGE_REQUEST} from "../../actions/socket";
import {deleteItemFromStorage} from "../components/storage";
import {generateUUID} from "../components/uuid";

const crewMiddleware = ({dispatch, getState}) => next => action => {
    next(action);

    switch (action.type) {
        case CLOCK_IN_EMPLOYEE:
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                message: {
                    method: "clock_in",
                    kwargs: {
                        "employee_number": action.payload,
						"request_id": generateUUID(),
                    }
                }
            })
            break;
        case REQUEST_CLOCK_IN_EMPLOYEE:
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                message: {
                    method: "clock_in_request",
                    kwargs: {
                        "employee_number": action.payload,
						"request_id": generateUUID(),
                    }
                }
            })
            break;


        case CLOCK_OUT_EMPLOYEE:
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                message: {
                    method: "clock_out",
                    kwargs: {
                        "employee_number": action.payload,
						"request_id": generateUUID(),
                    }
                }
            })
            break;

        
        case EMPLOYEE_CLOCK_OUT_REQUEST_SUCCESS: {
            const crew = {...getState().crew.crew}
            delete crew[action.payload.employee.employee_number]

            if (Object.keys(crew).length === 0) {
                deleteItemFromStorage("crew");
            }
        }

        default:
            break;
    }
};

export default crewMiddleware;