import styled from "styled-components";


export const Center = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  display: flex;
  color: ${({ theme, type }) => theme.palette[type || "normal"].main};
`;

export const SubTitle = styled.div`
  text-align: center;
`;