import {Button, useTheme} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {setLocationPermission, setNotificationPermission} from '../../redux/actions/permissions';
import {askLocationPermission, locationPermission} from '../../redux/middleware/components/geoLocation';
import {askNotificationPermission, notificationPermission} from '../../redux/middleware/components/pushNotifications';
import {getItemFromStorage} from '../../redux/middleware/components/storage';
import Card from '../components/card';
import NavBar from "../components/navBar";

const Permissions = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const geolocationPermission = useSelector(state => state.permissions.locationPermission);
    const pushNotificationPermission = useSelector(state => state.permissions.notificationPermission);
    const isRunningInApp = getItemFromStorage("runningInApp") === "true";

    useEffect(() => {
        const fetchData = async () => {
            if (!isRunningInApp) {
                dispatch(setNotificationPermission(notificationPermission()));
            }
            dispatch(setLocationPermission(await locationPermission()));
        };
        fetchData();
    }, [dispatch]);

    return (
        <>
            <NavBar title={"Grant permissions"} status={null} menu={true} />
            <Container theme={theme}>
                {geolocationPermission !== "denied" && pushNotificationPermission !== "denied" &&
                    <Content>
                        <Instruction>Please tap the buttons and <Success theme={theme}>Allow</Success> the required permissions</Instruction>
                        {!isRunningInApp && <PermissionButton onClick={() => askNotificationPermission(dispatch)} theme={theme} color={theme.palette.success.main} active={pushNotificationPermission === "granted"}>{pushNotificationPermission === "granted" ? 'Notifications Allowed' : 'Notifications'}</PermissionButton>}
                        <PermissionButton onClick={async () => askLocationPermission(dispatch)} theme={theme} color={theme.palette.success.main} active={geolocationPermission === "granted"}>{geolocationPermission === "granted" ? 'Location Allowed' : 'Location'}</PermissionButton>
                    </Content>}
                {(geolocationPermission === "denied" || (!isRunningInApp && pushNotificationPermission === "denied")) &&
                    <Card>
                        <Center>
                            <Title theme={theme}>Permission(s) Blocked</Title>
                            <SubTitle>Please click the button below to load a video which will help you to fix the permissions.</SubTitle>
                            <LinkButton theme={theme}>
                                <a href="http://app-permissions-video.netcarelab.co.za/" target="_blank">Fix Permissions</a>
                            </LinkButton>
                        </Center>
                    </Card>
                }
            </Container>
        </>
    );
};

export default Permissions;

const Container = styled.div`
    width: calc(100% - 2rem);
    height: calc(100vh - 120px - 1rem);
    padding: 0.5rem 1rem;
    background: ${({theme}) => theme.palette.background.default};
    overflow-y: hidden;
`;

const Content = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 5px;
`;

const PermissionButton = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    width: calc(100% - 2rem);
    height: calc(56px - 1rem);

    padding: 0.5rem 1rem;
    margin: 1rem 0;

    border-radius: 0.5rem;
    color: ${({active, color}) => (active ? color : "white")};
    background: ${({theme}) => theme.palette.background.darker};
    border: 1px solid ${({theme, active, color}) => (active ? color : theme.palette.background.darker)};
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  display: flex;
  color: ${({theme}) => theme.palette.error.main};
  padding-bottom: 0.5rem;
  text-align: center;
`;

const SubTitle = styled.div`
//   display: flex;
`;

const Instruction = styled.div`
    color: rgba(255, 255, 255, 0.75);
    font-weight: 500;
    padding: 0.5rem 0 0;
`;

const Success = styled.span`
    color: ${({theme}) => theme.palette.success.main};
`;

const LinkButton = styled(Button)`
    &.MuiButton-root {
        margin: 1rem 0 .5rem;
        padding: .25rem .75rem;
        background: ${({theme}) => theme.palette.background.lighter}};

        a {
            text-decoration: none;
            font-weight: bold;
            color: rgba(255,255,255,1);
        }
    }
`;