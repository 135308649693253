import React from "react";
import Card from "../../components/card";
import {CASE_STATES} from "../../../constants";
import {Center, Title, SubTitle} from "./styledOverviewComponents";
import {useTheme} from "@material-ui/core";


const AutoTransitionCancelBanner = ({timeRemaining, activeCase}) => {
    const theme = useTheme();

    if (timeRemaining <= 0) return null

    return (
        <Card>
            <Center>
                <Title theme={theme} type={"error"}>Automatic State Transition</Title>
                <SubTitle>
                    Looks like you have arrived at your destination and your state will be changed to <b>{CASE_STATES[activeCase?.case_state].next_state}</b>.
                    <br/>
                    <br/>
                    If you don't want to be transitioned, please <b>cancel</b> within {timeRemaining} second(s).
                </SubTitle>
            </Center>
        </Card>
    )
}

export default AutoTransitionCancelBanner;