import React, {useEffect} from "react";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {useTheme} from "@material-ui/core";
import {setActiveTab} from "../../redux/actions/application";
import {useDispatch, useSelector} from "react-redux";

const TabBar = ({tabs, enabled}) => {
    const activeTab = useSelector(state => state.application.activeTab)
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        const defaultTab = tabs.find((tab) => tab.title === activeTab)
        if (defaultTab.link !== history.location) {
            history.replace(defaultTab.link);
        }
    }, [])

    return (
        <Footer theme={theme} enabled={enabled}>
            {tabs.map((tab, index) => (
                <Icon
                    key={index}
                    theme={theme}
                    active={activeTab === tab.title}
                    onClick={() => {
                        dispatch(setActiveTab(tab.title))
                        history.replace(tab.link);
                    }}
                >
                    {<tab.icon />}
                </Icon>
            ))}
        </Footer>
    );
};

export default TabBar;

const Footer = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    padding: 0.25rem 0;

    width: 100%;
    height: calc(60px - 0.5rem);

    background: ${({theme}) => theme.palette.background.header};
    pointer-events: ${({enabled}) => enabled ? "unset" : "none"};

    div {
        opacity: ${({enabled}) => enabled ? "1" : "0.5"};
    }
`;

const Icon = styled.div`
    display: block;
    height: 45px;
    width: 45px;

    border-radius: 50%;
    color: ${({active, theme}) => (active === true ? theme.palette.primary.main : "white")};
    background: ${({active, theme}) => (active === true ? theme.palette.background.lighter : "")};

    svg {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    transition: 300ms;
`;
