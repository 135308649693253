import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";
import {Paper} from "@material-ui/core";


function SimpleDialog(props) {
    const {onClose, open, title, children} = props;

    const handleClose = () => {
        onClose && onClose();
    };

    return (
        <StyledDialog
            fullWidth
            maxWidth={"lg"}
            PaperComponent={StyledPaper}
            onClose={handleClose} open={open}>
            <DialogTitle>{title}</DialogTitle>
            {children}
        </StyledDialog>
    );
}

export default SimpleDialog;

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: #30344180;
  }
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-rounded {
    border-radius: 12px;
  }
  &.MuiPaper-root {
    background-color: #252836;
  }
`;