import axios from "axios";
import {normalize} from 'normalizr';
import {endNetwork, startNetwork} from '../../actions/ui';
import * as actions from '../../action_types/action-types';

const ROOT_URL = process.env.REACT_APP_API_ENDPOINT;
const REQUEST_TIMEOUT = 30 * 1000;

const getOptions = (authenticate = false, contentType = false, headers = {}) => {
    let options = {
        timeout: REQUEST_TIMEOUT
    };

    if (authenticate) {
        headers = {
            ...headers,
            Authorization: localStorage.getItem('token')
        };
    }

    if (contentType) {
        headers = {...headers, "Content-Type": contentType};
    }

    if (contentType) {
        options = {...options, headers: {"Content-Type": contentType}};
    }

    options = {
        ...options,
        headers: headers
    };

    return options;
};

const api = ({dispatch, getState}) => next => action => {

    if (action.type !== actions.API) {
        return next(action);
    }

    const {url, method, data, success, failure, label, authenticate, contentType, schema, callback, full_url, headers, file, uploadProgress} = action.payload;

    const api_url = full_url || `${ROOT_URL}/${url}`;

    dispatch(startNetwork(label));

    let requestPromise = null;

    switch (method) {
        case actions.API_GET:
            requestPromise = axios.get(api_url, getOptions(authenticate, false, headers));
            break;
        case actions.API_POST:
            let options = getOptions(authenticate, contentType, headers);
            if (uploadProgress)
                options = {...options, onUploadProgress: (data) => dispatch(uploadProgress(data))};
            if (file)
                data.append("file", file);
            requestPromise = axios.post(api_url, data, options);
            break;
        case actions.API_PATCH:
            requestPromise = axios.patch(api_url, data, getOptions(authenticate, contentType, headers));
            break;
        case actions.API_PUT:
            requestPromise = axios.put(api_url, data, getOptions(authenticate, contentType, headers));
            break;
        default:
            break;
    }

    if (requestPromise) {
        requestPromise
            .then(response => response.data)
            .then(_data => {
                if (schema) {
                    _data = normalize(_data, schema);
                }

                if (success)
                    dispatch(success(_data));
                if (callback)
                    callback(_data);

                dispatch(endNetwork(label));
            })
            .catch(error => {
                let errorObject = JSON.parse(JSON.stringify(error));
                if (errorObject.response && errorObject.response.status !== undefined) {
                    console.log("Error: " + errorObject.response.status + ', ' + errorObject.response.data);

                    const FAILED_AUTH_RESPONSES = [
                        'An error occurred while validating Authentication Token',
                        'Session has expired',
                        'Invalid Signature'
                    ];

                    let errorMessage;
                    try {
                        errorMessage = errorObject.response.data['body'] ? errorObject.response.data['body'] : errorObject.response.data;
                    }
                    catch (err) {

                    }

                    if (errorObject.response.status === 401 && FAILED_AUTH_RESPONSES.indexOf(errorMessage) >= 0) {
                    }
                }
                else {
                    console.log(error);
                    dispatch(endNetwork(label));
                }
                if (failure) {
                    dispatch(failure(error));
                }
                dispatch(endNetwork(label));
            });
    }


};

export default api;
