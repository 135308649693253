import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useTheme} from "@material-ui/core";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import {clockOutEmployee} from "../../redux/actions/crew";
import SimpleDialog from "./dialog";

const SignOff = ({employee}) => {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSignOff = () => {
        dispatch(clockOutEmployee(employee.employee_number));
        setOpen(false);
    };

    const renderSignOff = () => {
        return (
            <React.Fragment>
                <DialogTextPadding>
                    Are you sure you want to sign off, {employee.name} {employee.surname}?
                </DialogTextPadding>
                <DialogButtonDiv>
                    <DialogButton variant={'outlined'} onClick={handleClose}>
                        Cancel
                    </DialogButton>
                    <DialogButton variant={'outlined'} onClick={handleSignOff}>
                        Sign off
                    </DialogButton>
                </DialogButtonDiv>
            </React.Fragment>
        );
    };

    return (
        <div>
            <ErrorButton theme={theme}>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Sign off
                </Button>
            </ErrorButton>
            <SimpleDialog theme={theme} open={open} title={"Sign Off"}>
                <Padding>
                    {renderSignOff()}
                </Padding>
            </SimpleDialog>
        </div>
    );
};

export default SignOff;

const Padding = styled.div`
  padding: 10px;
`;

const ErrorButton = styled.div`
    Button {
        color: ${({theme}) => theme.palette.error.main};
        border-color: ${({theme}) => theme.palette.error.main};
        width: max-content;
    }
`;

const DialogTextPadding = styled.div`
  padding: 0 5px 10px 10px;
`;

const DialogButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
`;

const DialogButton = styled(Button)`
  width: 45%;
`;
