import React, {useEffect, useState} from 'react';
import Card from "./card";
import {useTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
    loginUsingAuthToken,
    loginUsingAuthTokenAndDeviceId,
    resetCellphone,
    submitCellphone
} from "../../redux/actions/vehicle";
import {useParams} from 'react-router-dom'
import TextInputCard from "./textInputCard";


const CellphoneSignIn = () => {
    const [step, setStep] = useState('cell_no')
    const [cellphoneNumber, setCellNumber] = useState("");
    const cellNumber = useSelector(state => state.vehicle.cellphoneNumber)
    const authError = useSelector(state => state.vehicle.authError)
    const dispatch = useDispatch();
    const theme = useTheme();
    const {authToken, deviceId} = useParams();

    useEffect(() => {
        if (authToken && deviceId)
            return dispatch(loginUsingAuthTokenAndDeviceId(authToken, deviceId))

        if (authToken)
            return dispatch(loginUsingAuthToken(authToken))

    }, [authToken, deviceId, dispatch])

    const onSignIn = (cellphoneNo) => {
        dispatch(submitCellphone(cellphoneNo));
        setStep('message')
    }

    const isError = () => {
        return isNaN(cellphoneNumber) || !cellphoneNumber || cellphoneNumber.length !== 10
    }

    if (authError)
        return (
            <Card>
                Error: {authError}
            </Card>
        )

    if (step !== 'cell_no')
        return (
            <Card>
                <Padding>
                    {cellNumber !== "error" ?
                        <React.Fragment>
                            <p>An SMS will be sent to your device. Please click the link in the SMS to continue
                                sign-in</p>
                            <p>
                                Not <CellphoneSpan color={theme.palette.myERA.green}>{cellphoneNumber}</CellphoneSpan>?
                            </p>
                        </React.Fragment>

                        :
                        <Error>
                            An error has occurred, please check the cellphone number and try again or contact the call
                            center for assistance
                        </Error>
                    }

                    <Button
                        style={{width: "100%"}}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            dispatch(resetCellphone())
                            setStep('cell_no')
                        }}>
                        Change Cellphone Number
                    </Button>
                </Padding>
            </Card>
        );

    return (
        <TextInputCard
            label={"Cellphone Number"}
            prompt={"Please enter the vehicle's cellphone number"}
            onSubmit={onSignIn}
            buttonText={"Sign In"}
            onChange={setCellNumber}
            type={"number"}
            disabled={isError()}
            error={(cellphoneNumber.length > 0 && isError()) ? "Please enter the cellphone number in the following format: 0831234567" : ""}
        />
    );
};

export default CellphoneSignIn;

const CellphoneSpan = styled.span`
  color: ${({color}) => color};
`;

const Error = styled.p`
  color: red;
`;

const Padding = styled.div`
  padding: .5rem;
`;