import { CONNECTION_CHANGED, REQUEST_SOCKET_CONNECT, REQUEST_SOCKET_DISCONNECT, SET_ACTION_QUEUE, SET_MESSAGE_QUEUE } from '../actions/socket';

const INITIAL_STATE = {
  connected: false,
  shouldConnect: true,
  messageQueue: null,
};

function socketReducer(state = INITIAL_STATE, action) {
  let reduced;
  switch (action.type) {
    case SET_MESSAGE_QUEUE:
        return {...state, messageQueue: action.payload};
    case CONNECTION_CHANGED:
      reduced = Object.assign({}, state, {
        connected: action.connected,
        isError: false
      });
      break;
    case REQUEST_SOCKET_CONNECT:
      return {...state, shouldConnect: true};
    case REQUEST_SOCKET_DISCONNECT:
      return {...state, shouldConnect: false};
    default:
      reduced = state;
  }
  return reduced;
}

export default socketReducer;