// Generic perform no operation action. No reducer should use this.
export const NO_OP = 'NO_OP';


// api
export const API = 'API';
export const API_POST = 'API_POST';
export const API_GET = 'API_GET';
export const API_PATCH = 'API_PATCH';
export const API_PUT = 'API_PUT';


// network state
export const END_NETWORK = 'END_NETWORK';
export const START_NETWORK = 'START_NETWORK';

// storage
export const LOAD_LOCAL_STORAGE = 'LOAD_LOCAL_STORAGE';
export const LOCAL_STORAGE_LOADED = 'LOCAL_STORAGE_LOADED';
