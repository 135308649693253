import React from 'react';
import ResponseApplication from "./responseApplication";
import UnauthenticatedRoutes from "./unauthenticatedRoutes";
import {useSelector} from "react-redux";
import Permissions from './permissions';
import {getItemFromStorage} from "../../redux/middleware/components/storage";

const AuthenticationManager = () => {
    const isAuthenticated = useSelector(state => state.application.authorized);
    const locationPermission = useSelector(state => state.permissions.locationPermission);
    const notificationPermission = useSelector(state => state.permissions.notificationPermission);

    const isRunningInApp = getItemFromStorage("runningInApp") === "true";

    if (!isAuthenticated)
        return <UnauthenticatedRoutes/>

    if (!isRunningInApp && (notificationPermission !== "granted" || locationPermission !== "granted"))
        return <Permissions/>

    return <ResponseApplication/>
};

export default AuthenticationManager;