import { useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const WSConnectionBanner = ({ status, title, subtitle }) => {
    const theme = useTheme();
    // status has the following possible values => "error" | "warning" | "normal" | "success"
    return <Banner status={status} theme={theme}>{title}{subtitle ? `\n${subtitle}` : ''}</Banner>;
};

export default WSConnectionBanner;

const Banner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    ${({status, theme}) => {
        switch (status) {
            case "error":
                return `color: ${theme.palette.error.main}; background: rgba(255, 0, 0, 0.1)`;
            case "warning":
                return `color: ${theme.palette.warning.main}; background: rgba(255, 150, 0, 0.1)`;
            case "success":
                return `color: ${theme.palette.success.main}; background: rgba(90, 255, 0, 0.1)`;
            default:
                return `color: ${theme.palette.primary.main}; background: rgba(0, 135, 255, 0.1)`;
        }
    }};

    margin: .5rem 0;
    padding: .5rem;

    border-radius: 0.5rem;
    
    white-space: pre;
    text-align: center;
`;
