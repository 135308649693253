import React from 'react';
import styled from "styled-components";

const VersionFooter = () => {
    return (
        <Container>
            Version {process.env.REACT_APP_VERSION}
        </Container>
    );
};

export default VersionFooter;

const Container = styled.div`
  position: absolute;
  bottom: 10%;
  width: calc(100% - 2rem);
  display: flex;
  align-items: center;
  justify-content: center;
  color: lightslategrey;
`;