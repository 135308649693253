import * as actions from "../action_types/action-types";

export const SET_CELLPHONE_NUMBER = "SET_CELLPHONE_NUMBER";
export const SUBMIT_CELLPHONE_NUMBER = "SUBMIT_CELLPHONE_NUMBER";
export const SUBMIT_CELLPHONE_SUCCESS = "SUBMIT_CELLPHONE_SUCCESS";
export const SUBMIT_CELLPHONE_FAIL = "SUBMIT_CELLPHONE_FAIL";
export const RESET_CELLPHONE_NUMBER = "RESET_CELLPHONE_NUMBER";
export const LOGIN_USING_AUTH_TOKEN = "LOGIN_USING_AUTH_TOKEN";
export const LINK_CELLPHONE_SUCCESS = "LINK_CELLPHONE_SUCCESS";
export const LINK_CELLPHONE_FAIL = "LINK_CELLPHONE_FAIL";
export const SET_CALL_SIGN = "SET_CALL_SIGN";
export const SET_AVAILABILITY = "SET_AVAILABILITY";
export const SET_ACTIVE_CASE = "SET_ACTIVE_CASE";
export const CANCEL_ACTIVE_CASE = "CANCEL_ACTIVE_CASE";
export const SET_VEHICLE_STATUS = "SET_VEHICLE_STATUS";
export const UPDATE_VEHICLE_STATUS = "UPDATE_VEHICLE_STATUS";
export const NEW_CASE = "NEW_CASE";
export const CASE_UPDATED = "CASE_UPDATED";
export const CHANGE_CASE_STATE = "CHANGE_CASE_STATE";
export const CLEAR_CANCELLED_CASE = "CLEAR_CANCELLED_CASE";
export const UPDATE_AUTHORIZATION_TOKEN = "UPDATE_AUTHORIZATION_TOKEN";
export const CLEAR_CASE_STATE_UPDATE = "CLEAR_CASE_STATE_UPDATE";
export const LOGIN_USING_AUTH_TOKEN_AND_DEVICE_ID = "LOGIN_USING_AUTH_TOKEN_AND_DEVICE_ID";
export const SET_DEVICE_ID = "SET_DEVICE_ID";
export const UPDATE_DEVICE_ID = "UPDATE_DEVICE_ID";
export const UPDATE_DEVICE_ID_SUCCESS = "UPDATE_DEVICE_ID_SUCCESS";
export const UPDATE_DEVICE_ID_FAIL = "UPDATE_DEVICE_ID_FAIL";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const UPDATE_LOCAL_CASE_STATE = "UPDATE_LOCAL_CASE_STATE";
export const REASSIGN_DEVICE_TO_VEHICLE = "REASSIGN_DEVICE_TO_VEHICLE";
export const RELOAD_VEHICLE_FROM_SERVER = "RELOAD_VEHICLE_FROM_SERVER";
export const CANCEL_AUTO_TRANSITION = "CANCEL_AUTO_TRANSITION";
export const ACKNOWLEDGE_AUTO_TRANSITION = "ACKNOWLEDGE_AUTO_TRANSITION";

export const changeCaseState = (caseNumber, currentState, nextState, cancellationReason, facility, patientInfo) => ({
    type: CHANGE_CASE_STATE,
    payload: {
        caseNumber,
        currentState,
        nextState,
        cancellationReason,
        facility,
        patientInfo
    }
})

export const clearCancelledCase = () => ({
    type: CLEAR_CANCELLED_CASE
})

export const acknowledgeAutoTransition = (payload) => ({
    type: ACKNOWLEDGE_AUTO_TRANSITION,
    payload: payload
})

export const cancelAutoTransition = (payload) => ({
    type: CANCEL_AUTO_TRANSITION,
    payload: payload
})

export const setVehicleStatus = (payload) => ({
    type: SET_VEHICLE_STATUS,
    payload: payload
})

export const setDeviceId = (deviceId) => ({
    type: SET_DEVICE_ID,
    payload: deviceId
})

export const updateDeviceId = (deviceId) => ({
    type: UPDATE_DEVICE_ID,
    payload: deviceId
})

export const authenticationError = (payload) => ({
    type: AUTHENTICATION_ERROR,
    payload
})

export const clearCaseStateUpdate = () => ({
    type: CLEAR_CASE_STATE_UPDATE,
})

export const updateVehicleStatus = () => ({
    type: UPDATE_VEHICLE_STATUS
})

export const submitCellphoneNumber = (cellphoneNumber) => ({
    type: SUBMIT_CELLPHONE_NUMBER,
    payload: cellphoneNumber
})

export const cellphoneSuccess = (cellphoneNumber) => ({
    type: SUBMIT_CELLPHONE_SUCCESS,
    payload: cellphoneNumber
})

export const cellphoneFail = (cellphoneNumber) => ({
    type: SUBMIT_CELLPHONE_FAIL,
    payload: cellphoneNumber
})


export const linkCellphoneSuccess = (cellphoneNumber) => ({
    type: LINK_CELLPHONE_SUCCESS,
    payload: cellphoneNumber
})

export const linkCellphoneFail = (cellphoneNumber) => ({
    type: LINK_CELLPHONE_FAIL,
    payload: cellphoneNumber
})

export const reassignDeviceToVehicle = (payload) => ({
    type: REASSIGN_DEVICE_TO_VEHICLE,
    payload: payload
})

export const resetCellphone = (cellphoneNumber) => ({
    type: RESET_CELLPHONE_NUMBER,
    payload: null
})

export const setCellphoneNumber = (cellphoneNumber) => ({
    type: SET_CELLPHONE_NUMBER,
    payload: cellphoneNumber
})

export const setAvailability = (vehicleAvailability, availabilityReason) => ({
    type: SET_AVAILABILITY,
    payload: {vehicleAvailability, availabilityReason}
})

export const setUpdatedCase = (updatedCase) => ({
    type: CASE_UPDATED,
    payload: updatedCase
})

export const setActiveCase = (activeCase) => ({
    type: SET_ACTIVE_CASE,
    payload: activeCase
})

export const updateLocalCaseState = (state, time) => ({
    type: UPDATE_LOCAL_CASE_STATE,
    payload: {state, time}
})

export const cancelActiveCase = () => ({
    type: CANCEL_ACTIVE_CASE,
    payload: null
})

export const setCallSign = (callSign) => ({
    type: SET_CALL_SIGN,
    payload: callSign
})

export const loginUsingAuthToken = (authToken) => ({
    type: LOGIN_USING_AUTH_TOKEN,
    payload: authToken
})

export const loginUsingAuthTokenAndDeviceId = (authToken, deviceId) => ({
    type: LOGIN_USING_AUTH_TOKEN_AND_DEVICE_ID,
    payload: {authToken, deviceId}
})

export const reloadVehicleFromServer = () => ({
    type: RELOAD_VEHICLE_FROM_SERVER,
    payload: null
})

export const updateDeviceIdSuccess = (deviceId) => ({
    type: UPDATE_DEVICE_ID_SUCCESS,
    payload: deviceId
})

export const updateDeviceIdFail = (deviceId) => ({
    type: UPDATE_DEVICE_ID_FAIL,
    payload: deviceId
})

export const submitCellphone = (cellphone_number) => ({
    type: actions.API,
    payload: {
        url: 'rpc',
        method: actions.API_POST,
        data: {
            'controller': 'ApiController',
            'method': 'login',
            'kwargs': {
                'cellphone_number': cellphone_number
            }
        },
        failure: (data) => cellphoneFail(data),
        success: (data) => cellphoneSuccess(cellphone_number),
        label: 'login',
        authenticate: false
    }
});

export const loginUsingAuthTokenAndCellphoneNumber = (cellphone_number, authToken) => ({
    type: actions.API,
    payload: {
        url: 'rpc',
        method: actions.API_POST,
        data: {
            'controller': 'ApiController',
            'method': 'link_cellphone_to_device',
            'kwargs': {
                'cellphone_number': cellphone_number,
                'code': authToken
            }
        },
        failure: (data) => linkCellphoneFail(data),
        success: (data) => linkCellphoneSuccess(data),
        label: 'login',
        authenticate: false
    }
});

export const loginUsingAuthTokenAndCellphoneNumberandDeviceId = (cellphone_number, authToken, deviceId) => ({
    type: actions.API,
    payload: {
        url: 'rpc',
        method: actions.API_POST,
        data: {
            'controller': 'ApiController',
            'method': 'link_cellphone_to_device_with_id',
            'kwargs': {
                'cellphone_number': cellphone_number,
                'device_id': deviceId,
                'code': authToken
            }
        },
        failure: (data) => linkCellphoneFail(data),
        success: (data) => linkCellphoneSuccess(data),
        label: 'login',
        authenticate: false
    }
});

export const updateDeviceIdOnBackend = (authToken, deviceId) => ({
    type: actions.API,
    payload: {
        url: 'rpc',
        method: actions.API_POST,
        data: {
            'controller': 'ApiController',
            'method': 'update_device_id',
            'kwargs': {
                'authorization_token': authToken,
                'device_id': deviceId
            }
        },
        failure: (data) => updateDeviceIdFail(data),
        success: (data) => updateDeviceIdSuccess(deviceId),
        label: 'login',
        authenticate: false
    }
});