import React from 'react';
import styled from 'styled-components';
import {IconButton, useTheme} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import Break from "./break";

const Card = ({children, dismissable, onDismiss, title}) => {
  const theme = useTheme();


  return (
    <Container theme={theme}>
      {dismissable &&
        <CloseButton onClick={onDismiss}>
          <Close />
        </CloseButton>
      }
      {dismissable && (title ? <Title theme={theme}>{title}</Title> : <Break margin={"15px"} />)}
      {children}
    </Container>
  );
};

export default Card;

const Container = styled.div`
  position: relative;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.background.darker};
  padding: 1rem;
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex;
`;

const CloseButton = styled(IconButton)`
  &.MuiButtonBase-root {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Title = styled.div`
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: capitalize;
  color: ${({theme}) => theme.palette.background.subtitle};
`;