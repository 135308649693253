import * as actions from '../../action_types/action-types';
import {saveLocalStorageToState} from "../../actions/storage";

export const DATA_TYPE_TEXT = "DATA_TYPE_TEXT";
export const DATA_TYPE_JSON = "DATA_TYPE_JSON";

const storage = ({dispatch}) => next => action => {
    if (action.type === actions.LOAD_LOCAL_STORAGE) {

        const dataToLoad = action.payload;

        dataToLoad.forEach(({key, type}) => {

            let data = null;
            if (type === DATA_TYPE_JSON) {
                try {
                    data = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
                } catch (e) {

                }
            } else if (type === DATA_TYPE_TEXT) {
                data = localStorage.getItem(key) || null;
            }

            dispatch(saveLocalStorageToState({[key]: data}));

        });
    } else {
        return next(action);
    }
};

export default storage;
