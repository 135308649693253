import {subscribeNotifications} from "../components/pushNotifications";
import {SET_NOTIFICATION_PERMISSION, SET_LOCATION_PERMISSION} from "../../actions/permissions";
import {addItemToStorage, getItemFromStorage} from "../components/storage";

const permissionsMiddleware = ({dispatch, getState}) => next => action => {
    next(action);

    switch (action.type) {
        case SET_NOTIFICATION_PERMISSION: {
            const hasPermission = action.payload;
            addItemToStorage("permission_notifications", hasPermission);
            
            // true (if server has it) || subscription (if server doesn't have it)
            const hasSubscription = getItemFromStorage("push_subscription"); 
            if (hasPermission) {
                subscribeNotifications(dispatch);
            }
            break;
        }
        case SET_LOCATION_PERMISSION: {
            const hasPermission = action.payload;
            addItemToStorage("permission_location", hasPermission);
            break;
        }
        default:
            break;
    }
};

export default permissionsMiddleware;