import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {CASE_STATES} from "../../../constants";

export const useAutoTransition = () => {
    const autoTransitionTime = useSelector(state => state.vehicle?.activeCase?.auto_transition_time);
    const caseState = useSelector(state => state.vehicle?.activeCase?.case_state)
    const autoTransitionStatus = useSelector(state => state.vehicle?.activeCase?.auto_transition_prompt_status)
    const deadline = autoTransitionTime ?? (new Date().getTime() + (30 * 1000))

    const parsedDate = Date.parse(deadline) - 1000 // Deduct 1s from the deadline to avoid timeout edge-case
    const [timeRemaining, setTimeRemaining] = useState(Math.round((parsedDate - Date.now()) / 1000) || 10)

    useEffect(() => {
        const interval = setInterval(() => setTimeRemaining(Math.round((parsedDate - Date.now()) / 1000)), 250);
        return () => clearInterval(interval);
    }, [deadline])

    return {
        timeRemaining,
        visible: caseState &&
            autoTransitionStatus === 'Visible' && ["Mobile incident", "Mobile hospital"].includes(caseState)
    }
}