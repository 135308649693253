import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './react/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3e67379330df46c697c40dc91433bc4c@o1077779.ingest.sentry.io/4505481359851520",
  integrations: [
    new Sentry.BrowserTracing(),
  ],
  release: `911myera@${process.env.REACT_APP_VERSION}`,
  environment: process.env.REACT_APP_STAGE,
  tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!
});

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
