import React, {useCallback, useEffect} from "react";
import Card from "../../components/card";
import {CASE_STATES} from "../../../constants";
import SwipeableButton from "../../components/swipeableButton";
import {cancelAutoTransition} from "../../../redux/actions/vehicle";
import {Center, SubTitle, Title} from "./styledOverviewComponents";
import {useDispatch} from "react-redux";
import {useTheme} from "@material-ui/core";


const useHandleSubmit = () => {
    const dispatch = useDispatch();
    let caseStateChangeDelay = null;

    useEffect(() => {
        return () => clearTimeout(caseStateChangeDelay)
    }, [])

    const handleSubmit = useCallback((caseNumber) => {
        caseStateChangeDelay = setTimeout(() => {
            dispatch(cancelAutoTransition({case_number: caseNumber}))
        }, 1000)
    }, [])

    return handleSubmit
}

const InProgressBanner = ({ activeCase }) => {
    const theme = useTheme();
    let state = activeCase?.case_state

    if (["Mobile incident", 'Mobile hospital'].includes(activeCase?.case_state)) {
        // Prevent an invalid autoTransition state from being displayed
        state = CASE_STATES[activeCase?.case_state].next_state
    }

    return (
        <Card>
            <Center>
                <Title theme={theme} type={"error"}>Automatic Transition Active</Title>
                <SubTitle>
                    Cancel window has passed, case is being transitioned
                    to <b>{state}</b> automatically.
                </SubTitle>
            </Center>
        </Card>
    )
}

const AutoTransitionCancelButton = ({timeRemaining, activeCase}) => {
    const handleSubmit = useHandleSubmit()

    if (timeRemaining <= 0) return <InProgressBanner activeCase={activeCase} />

    return (
        <SwipeableButton
            onComplete={() => handleSubmit(activeCase.case_number)}
            label={"Cancel auto transition"}
            activeLabel={"Cancelled"}
            type={'error'}
            direction={"left"}/>
    );
}

export default AutoTransitionCancelButton;