export const CONNECTION_CHANGED = "CONNECTION_CHANGED";
export const REQUEST_SOCKET_CONNECT = "REQUEST_SOCKET_CONNECT";
export const REQUEST_SOCKET_DISCONNECT = "REQUEST_SOCKET_DISCONNECT";
export const CONNECT_SOCKET = "CONNECT_SOCKET";
export const DISCONNECT_SOCKET = "DISCONNECT_SOCKET";

export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const REQUEST_ACKNOWLEDGED = "REQUEST_ACKNOWLEDGED";

export const ADD_MESSAGE_TO_QUEUE = "ADD_MESSAGE_TO_QUEUE";
export const REMOVE_MESSAGE_FROM_QUEUE = "REMOVE_MESSAGE_FROM_QUEUE";
export const SET_MESSAGE_QUEUE = "SET_MESSAGE_QUEUE";

export const RESEND_QUEUED_MESSAGES = "RESEND_QUEUED_MESSAGES";

export const addMessageToQueue = (message) => ({
  type: ADD_MESSAGE_TO_QUEUE,
  payload: message
});

export const removeMessageFromQueue = (message) => ({
  type: REMOVE_MESSAGE_FROM_QUEUE,
  payload: message
});

export const setMessageQueue = (messageQueue) => ({
  type: SET_MESSAGE_QUEUE,
  payload: messageQueue
});

export const resendQueuedMessages = () => ({
  type: RESEND_QUEUED_MESSAGES
});

export const connectionChanged = (isConnected) => {
  return {
    type: CONNECTION_CHANGED,
    connected: isConnected,
    isError: false
  };
};

export const connectSocket = (token) => {
  return {
    type: CONNECT_SOCKET,
    payload: token
  };
};

export const disconnectSocket = () => {
  return {
    type: DISCONNECT_SOCKET
  };
};

export const messageReceived = (message) => {
  return {
    type: MESSAGE_RECEIVED,
    message,
  };
};

export const requestSocketConnect = (token) => ({ type: REQUEST_SOCKET_CONNECT, payload: token });
export const requestSocketDisconnect = () => ({ type: REQUEST_SOCKET_DISCONNECT });