import React from "react";
import styled from "styled-components";

const colors = ["#00AA55", "#009FD4", "#B381B3", "#939393", "#E3BC00", "#D47500", "#DC2A2A"];

const numberFromText = (text) => {
    const charCodes = text
        .split("") // => ["A", "A"]
        .map((char) => char.charCodeAt(0)) // => [65, 65]
        .join(""); // => "6565"
    return parseInt(charCodes, 10);
}

const Avatar = ({ fullName }) => {
    const initials = fullName.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')
    const reducedInitials = `${initials[0]}${initials[initials.length - 1]}`
    const backgroundColor = colors[numberFromText(fullName || "") % colors.length];
    const boxShadow = `none`;

    return (
        <AvatarDiv boxShadow={boxShadow} backgroundColor={backgroundColor}>
            {reducedInitials}
        </AvatarDiv>
    );
};

export default React.memo(Avatar);

const AvatarDiv = styled.div`
    border-radius: 50%;
    color: rgba(0,0,0,0.4);
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bolder;
    background-color: ${(props) => props.backgroundColor};
    box-shadow: ${(props) => props.boxShadow};
`;
