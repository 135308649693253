import {useTheme} from "@material-ui/core";
import DriveEtaRoundedIcon from '@material-ui/icons/DriveEtaRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import styled from "styled-components";
import {startAppTimer, stopAppTimer} from "../../redux/middleware/components/timer";
import NavBar from "../components/navBar";
import TabBar from "../components/tabBar";
import ClockIn from "./clockIn";
import EmployeeSignIn from "./clockIn/employeeSignIn";
import Overview from "./Overview";
import SetVehicleStatus from "./setVehicleStatus";
import CaptureRouter from "./captureRouter";
import useSound from "use-sound";
import {clearCaseStateUpdate} from "../../redux/actions/vehicle";
import Card from "../components/card";
import * as Sentry from '@sentry/react';


const ResponseApplication = () => {
    const vehicleStatus = useSelector(state => state.vehicle.availability);
    const callSign = useSelector(state => state.vehicle.callSign);
    const cellphoneNumber = useSelector(state => state.vehicle.cellphoneNumber);
    const deviceId = useSelector(state => state.vehicle.deviceId);
    const websocketConnected = useSelector(state => state.socket.connected);
    const navigationEnabled = useSelector(state => state.application.navigationEnabled);
    const caseStateUpdated = useSelector(state => state.vehicle.caseStateUpdate);
    const reloadingVehicle = useSelector(state => state.vehicle.reloadingVehicle);


    useEffect(() => {
        Sentry.setContext('user', {
            name: callSign,
            userId: cellphoneNumber,
            deviceId: deviceId,
        });
    }, [callSign, cellphoneNumber, deviceId])

    const theme = useTheme();
    const dispatch = useDispatch();
    const [playUpdateSound] = useSound("/sound/update.mp3");

    useEffect(() => {
        if (!caseStateUpdated) return;

        if (caseStateUpdated === 'new') {
            playUpdateSound()
        }
        if (caseStateUpdated === 'update') {
            playUpdateSound()
        }
        dispatch(clearCaseStateUpdate())
    }, [caseStateUpdated])

    const tabs = [
        {
            title: "Vehicle",
            icon: DriveEtaRoundedIcon,
            link: "/set-vehicle-status"
        },
        {
            title: "Home",
            icon: HomeRoundedIcon,
            link: "/"
        },
        {
            title: "Crew",
            icon: PeopleAltRoundedIcon,
            link: "/clock-in"
        },
    ];

    useEffect(() => {
        let appTimer = null;

        const initTimer = async () => {
            appTimer = await startAppTimer(dispatch);
        }

        initTimer();
        return () => {
            if (appTimer) {
                stopAppTimer();
                clearInterval(appTimer);
            }
        };
    }, [dispatch]);

    if (window.navigator.userAgent.indexOf('SamsungBrowser') > -1)
        return (
            <>
                <NavBar title={callSign} status={websocketConnected} menu={true}/>
                <Container theme={theme}>
                    <Card>
                        <p>It looks like you are using the 'Samsung Internet' app as your default browser</p>
                        <p>Please change your default browser to 'Chrome' to use the 'Response' app</p>
                    </Card>
                </Container>
            </>

        );

    if (!window.matchMedia('(display-mode: standalone)').matches)
        return (
            <>
                <NavBar title={callSign} status={websocketConnected} menu={true}/>
                <Container theme={theme}>
                    <Card>
                        <p>Please add to home screen to continue, you may open the app from your home screen</p>
                        <p>You may close this window after you have added to home screen</p>
                    </Card>
                </Container>
            </>

        );

    return (
        <ParentContainer>
            <NavBar title={callSign} status={websocketConnected} menu={true} loading={reloadingVehicle}/>
            <Container theme={theme}>
                <Switch>
                    <Route exact path="/id/:deviceId">
                        <Overview/>
                    </Route>
                    <Route exact path="/">
                        <Overview/>
                    </Route>
                    <Route exact path="/clock-in">
                        <ClockIn/>
                    </Route>
                    <Route exact path="/clock-in/employee-sign-in">
                        <EmployeeSignIn/>
                    </Route>
                    <Route exact path="/set-vehicle-status">
                        <SetVehicleStatus vehicleStatus={vehicleStatus}/>
                    </Route>
                    <Route path="/capture">
                        <CaptureRouter />
                    </Route>
                    <Redirect to="/"/>
                </Switch>
            </Container>
            <TabBar tabs={tabs} menu={true} enabled={navigationEnabled}/>
        </ParentContainer>
    )
        ;
};

export default ResponseApplication;

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const Container = styled.div`
  width: calc(100% - 2rem);
  height: calc(100vh - 120px - 1rem);
  padding: 0.5rem 1rem;
  background: ${({theme}) => theme.palette.background.default};
  overflow-y: hidden;
`;
