import styled from "styled-components";
import {Autocomplete, TextField} from "mui-rff";


export const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
        background: ({theme}) => theme.palette.background.darker,
        borderRadius: '0.5rem',
        color: 'rgba(255, 255, 255, 1)'
    },
    '& .MuiInputLabel-outlined': {
        color: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiInputLabel-shrink': {
        color: 'rgba(255, 255, 255, 1)'
    },
    '& .MuiFormHelperText-root': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .Mui-disabled': {
        color: 'rgba(255, 255, 255, 0.2) !important',
    },
    '&[data-autofilled="true"] .MuiOutlinedInput-root .Mui-disabled': {
        '-webkit-text-fill-color': 'rgba(255, 255, 255, 0.4) !important'
    },
})

export const StyledAutoComplete = styled(Autocomplete)({
    '& .MuiTextField-root .MuiOutlinedInput-root': {
        background: ({theme}) => theme.palette.background.darker,
        borderRadius: '0.5rem',
    },
    '& .MuiTextField-root .MuiInputLabel-outlined': {
        color: 'rgba(255, 255, 255, 0.7)'
    },
    '& .MuiTextField-root .MuiAutocomplete-input': {
        color: 'rgba(255, 255, 255, 1)'
    },
    '& .MuiTextField-root .MuiInputLabel-shrink': {
        color: 'rgba(255, 255, 255, 1)'
    },
})

export const StyledButton = styled.button`
  display: flex;

  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 3rem;  
  font-size: 1rem;

  padding: 0.5rem 1rem;
  margin: 1rem 0;

  border-radius: 0.5rem;
  color: ${({color}) => (color ? color : "white")};
  background: ${({theme}) => theme.palette.background.darker};
  border: 1px solid ${({theme, color}) => (color ? color : theme.palette.background.border)};

  &[disabled] {
    pointer-events: none;
    border: 1px solid ${({theme}) => theme.palette.background.border};
    color: ${({theme}) => theme.palette.background.border};
  }
`;

export const RemoveButton = styled(StyledButton)`
  color: ${({theme, color}) => (color ? color : theme.palette.error.main)};
  border: 1px solid ${({theme, color}) => (color ? color : theme.palette.error.main)};
`;

export const SubmitButton = styled(StyledButton)`
  color: white;
  background: ${({theme, color}) => (color ? color : theme.palette.secondary.main)};
  border: 1px solid ${({theme, color}) => (color ? color : theme.palette.secondary.main)};

  &[disabled] {
    background: ${({theme}) => theme.palette.background.darker};
  }
`;