export const SET_LOCATION_PERMISSION = "SET_LOCATION_PERMISSION";
export const SET_NOTIFICATION_PERMISSION = "SET_NOTIFICATION_PERMISSION";

export const setLocationPermission = (hasPermission) => ({
    type: SET_LOCATION_PERMISSION,
    payload: hasPermission
})

export const setNotificationPermission = (hasPermission) => ({
    type: SET_NOTIFICATION_PERMISSION,
    payload: hasPermission
})