import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {startApp} from "../../redux/actions/application";
import styled from "styled-components";
import NavBar from "../components/navBar";
import CellphoneSignIn from "../components/cellphoneSignIn";
import {useTheme} from '@material-ui/core';
import {useParams} from "react-router-dom";
import {updateDeviceId} from "../../redux/actions/vehicle";

const LandingPage = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const {deviceId} = useParams();
    const storedDeviceId = useSelector(state => state.vehicle.deviceId);

    useEffect(() => {
        if (deviceId && deviceId !== storedDeviceId) {
            dispatch(updateDeviceId(deviceId))
        }
    }, [storedDeviceId, deviceId])

    useEffect(() => {
        dispatch(startApp("Hello world"))
    }, [dispatch]) //Note since dispatch does not change, it is the equivalent of having an empty array so will only trigger once

    return (
        <>
            <NavBar title={"Sign In"} status={null} menu={false} />
            <Container theme={theme}>
                <Content>
                    <CellphoneSignIn />
                </Content>
            </Container>
        </>
    );
};

export default LandingPage;

const Container = styled.div`
    width: calc(100% - 2rem);
    height: calc(100vh - 120px - 1rem);
    padding: 0.5rem 1rem;
    background: ${({theme}) => theme.palette.background.default};
    overflow-y: hidden;
`;

const Content = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 5px;
`;