import {Button, IconButton, useTheme, Dialog} from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import AlarmRoundedIcon from '@material-ui/icons/AlarmRounded';
import CardTravelRoundedIcon from '@material-ui/icons/CardTravelRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DirectionsIcon from '@material-ui/icons/Directions';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import TabRoundedIcon from '@material-ui/icons/TabRounded';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import Break from "./break";

const openMapsLink = (url) => {
  if ((navigator.platform.indexOf("iPhone") !== -1)
    || (navigator.platform.indexOf("iPod") !== -1)
    || (navigator.platform.indexOf("iPad") !== -1))
    window.open(url.replace("https", "maps"));
  else
    window.open(url);
};

const toTitleCase = (text) => {
  return text.split(' ')
    .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');
};

const RenderHistoryItem = (item, index, theme) => {
  return (
    <DialogDetailItem theme={theme}
      key={index}>
      <Higlight theme={theme}>
        {`${new Date(item.MessageOutDateTime).toLocaleDateString("en-ZA")} ${new Date(item.MessageOutDateTime).toLocaleTimeString("en-ZA")}`}
      </Higlight>
      {item.Message}
    </DialogDetailItem>);
};

const RenderStateItem = (item, index, theme) => {
  return (
    <DialogDetailItem theme={theme}
      key={index}>
      <Higlight theme={theme}>
        {`${new Date(item.MessageOutDateTime).toLocaleDateString("en-ZA")} ${new Date(item.MessageOutDateTime).toLocaleTimeString("en-ZA")}`}
      </Higlight>
      {`Change to "${toTitleCase(item.Message.replace("_", " "))}" state`}
    </DialogDetailItem>);
};

const constructMapsLink = ({ destination: {lat, lon} } = {destination: {}}) => {
  if (!lat || !lon) return null
  return `https://maps.google.com/?q=${lat},${lon}`
}

const navigationEnabled = ({case_state, destination}) => {
  const navigationStates = ["Acknowledged", "Mobile incident", "At incident", "Mobile hospital"]
  return !(!destination?.lat || !destination?.lon || !navigationStates.includes(case_state));
}


const CaseInformation = ({activeCase}) => {
  const theme = useTheme();
  const vehicleCellphone = useSelector(state => state.vehicle.cellphoneNumber);
  const [showCaseDetails, setCaseDetails] = useState(false);

  const renderCaseDetails = () => {
    return (<Dialog aria-labelledby="customized-dialog-title" open={showCaseDetails}>
      <DialogTitle theme={theme} id="customized-dialog-title">
        Details ({activeCase.case_number})
      </DialogTitle>
      <DialogContent theme={theme} open={showCaseDetails} items={activeCase.event_history.length}>
        {detailItems.sort((a, b) => {
          return (new Date(a.MessageOutDateTime)).getTime() - (new Date(b.MessageOutDateTime)).getTime();
        }
        ).map((item, index) => RenderDetailItem(item, index, theme))}
      </DialogContent>
      <DialogActions theme={theme}>
        <Button autoFocus onClick={() => setCaseDetails(false)}>
          Done
        </Button>
      </DialogActions>
    </Dialog>);
  };

  const RenderDetailItem = (item, index, theme) => {
    return item?.stateItem ? RenderStateItem(item, index, theme) : RenderHistoryItem(item, index, theme);
  };

  const historyItems = (activeCase?.event_history || []).filter((item) => item.Destination === vehicleCellphone);
  const stateItems = (Object.keys(activeCase?.time_state_entered).map(key => {
    return {Message: key, MessageOutDateTime: `${activeCase?.time_state_entered[key]}`, stateItem: true};
  }) || []);
  const detailItems = [...historyItems, ...stateItems];
  const caseInformation = historyItems.filter(item => item.event_type === "Case Information");

  return (
    activeCase &&

    <Container theme={theme}>

      <CaseHeader>
        <Title theme={theme}>Basic Information</Title>
        <EndStatus theme={theme}>
          {activeCase.case_state}
        </EndStatus>
      </CaseHeader>

      <CaseBody>
        <BodyLine>
          <AlarmRoundedIcon />
          <Label>{`${new Date(activeCase.timestamp).toLocaleDateString()} ${new Date(activeCase.timestamp).toLocaleTimeString()}`}</Label>
        </BodyLine>

        <BodyLine>
          <CardTravelRoundedIcon />
          <Label>{activeCase.case_number}</Label>
        </BodyLine>

        <BodyLine>
          <TabRoundedIcon />
          <Label>{`${activeCase.case_category_id} - ${activeCase.case_category}`}</Label>
        </BodyLine>

        <BodyLine>
          <RoomRoundedIcon />
          <Label>{activeCase.facility ?? activeCase.address}</Label>
          <EndIcon theme={theme} enabled={navigationEnabled(activeCase)}>
            <IconButton
              onClick={() => {
                openMapsLink(constructMapsLink(activeCase));
              }}
            >
              <DirectionsIcon
                fontSize={'large'} />
            </IconButton>

          </EndIcon>
        </BodyLine>

      </CaseBody>

      <CaseHeader onClick={() => historyItems.length > 0 ? setCaseDetails(true) : ''}>
        <Title theme={theme}>Patient Details</Title>
      </CaseHeader>

      <CaseBody onClick={() => historyItems.length > 0 ? setCaseDetails(true) : ''}>
        <BodyLine>
          <ErrorRoundedIcon />
          <PatientDetails>
            {
              caseInformation.length > 0 ? caseInformation.map((item, index) => {
                return (
                  <Label key={index}>{item.event_details ? item.event_details : item.Message}</Label>);
              })
                :
                <Label>Case Information Not Available</Label>
            }
          </PatientDetails>

        </BodyLine>
      </CaseBody>

      {renderCaseDetails()}

    </Container>
  );
};

export default CaseInformation;

const PatientDetails = styled.div`
  overflow-y: auto;
  max-height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EndStatus = styled.span`
  margin-left: auto;
  margin-right: .5rem;
  padding: .2rem .4rem;
  color: ${({theme}) => theme.palette.warning.main};
  border: 1px solid ${({theme}) => theme.palette.warning.main};
  border-radius: .25rem;
  font-size: small;
`;

const EndIcon = styled.span`
  margin-left: auto;
  margin-right: .5rem;

  .MuiButtonBase-root {
    pointer-events: ${({enabled}) => enabled ? "unset" : "none"};
    color: ${({enabled}) => enabled ? "white" : "lightslategrey"};

    .MuiSvgIcon-root {
      fill: ${({enabled}) => enabled ? "white" : "lightslategrey"};
    }
  }
`;

const Label = styled.div`
  margin-left: 1rem;
  white-space: pre-line;
`;

const BodyLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: .65rem .25rem;
  font-size: medium;
`;

const Container = styled.div`
  font-size: 1.5rem;
  width: calc(100% - 2rem);
  max-height: 65% !important;
  overflow-y: hidden;
  min-height: 60px;

  padding: 1rem;

  background: ${({theme}) => theme.palette.background.darker};
  border-radius: .5rem;
`;

const Title = styled.div`
  display: flex;
  align-content: center;
  width: fit-content;
  color: ${({theme}) => theme.palette.secondary.lighter};
  font-size: medium;
`;

const Higlight = styled.div`
  width: 100%;
  color: #708090;
  font-size: smaller;
  padding: 0 0 0.25rem 0;
`;

const CaseHeader = styled.div`
  display: flex;
  min-height: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: smaller;
`;

const CaseBody = styled.div`
  width: 100%;
`;

const DialogTitle = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    background: ${({theme}) => theme.palette.background.header}; //#213142;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  &.MuiDialogContent-root {
    max-height: 40vh;
    background: ${({theme}) => theme.palette.background.default}; //#1c2733;
    padding: 0 .5rem;
  }
`;

const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    margin: 0;
    background: ${({theme}) => theme.palette.background.header}; //#213142;
  }
`;

const DialogDetailItem = styled.div`
  background: ${({theme}) => theme.palette.background.darker};;
  padding: 0.75rem;
  border-radius: .5rem;
  margin: .75rem .25rem;
`;