import {
    authenticationError,
    ACKNOWLEDGE_AUTO_TRANSITION,
    CANCEL_ACTIVE_CASE,
    CANCEL_AUTO_TRANSITION,
    CHANGE_CASE_STATE,
    CLEAR_CANCELLED_CASE,
    LINK_CELLPHONE_SUCCESS,
    REASSIGN_DEVICE_TO_VEHICLE,
    LOGIN_USING_AUTH_TOKEN,
    LOGIN_USING_AUTH_TOKEN_AND_DEVICE_ID,
    loginUsingAuthTokenAndCellphoneNumberandDeviceId,
    SET_AVAILABILITY,
    setActiveCase,
    setCallSign,
    setDeviceId,
    SUBMIT_CELLPHONE_SUCCESS,
    UPDATE_AUTHORIZATION_TOKEN,
    UPDATE_DEVICE_ID,
    UPDATE_DEVICE_ID_SUCCESS,
    UPDATE_VEHICLE_STATUS,
    updateDeviceIdOnBackend,
    CASE_UPDATED,
    RELOAD_VEHICLE_FROM_SERVER,
    SET_VEHICLE_STATUS
} from "../../actions/vehicle";
import {setCrew} from "../../actions/crew";
import {addItemToStorage, deleteItemFromStorage, getItemFromStorage} from "../components/storage";
import {getVehicleStatus, setAuthorization, setAuthorizationToken} from "../../actions/application";
import {CONNECTION_CHANGED, disconnectSocket, SEND_MESSAGE_REQUEST, setMessageQueue} from "../../actions/socket";
import {generateUUID} from "../components/uuid";
import {v4 as uuidv4} from 'uuid';

const vehicleMiddleware = ({dispatch, getState}) => next => action => {
    next(action);

    switch (action.type) {
        case CONNECTION_CHANGED:
            if (action.connected)
                dispatch({
                    type: SEND_MESSAGE_REQUEST,
                    message: {method: "get_status", kwargs: {}}
                });
            break;
        case CHANGE_CASE_STATE:
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                message: {
                    method: "change_case_state",
                    kwargs: {
                        "case_number": action.payload.caseNumber,
                        "case_state": action.payload.currentState,
                        "next_state": action.payload.nextState,
                        "cancellation_reason": action.payload.cancellationReason,
                        "facility": action.payload.facility,
                        "request_id": generateUUID(),
                        "patient_info": action.payload.patientInfo
                    }
                }
            });
            break;
        case CLEAR_CANCELLED_CASE:
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                message: {
                    method: "clear_cancelled_case",
                    kwargs: {
                        "request_id": generateUUID(),
                    }
                }
            });
            break;
        case ACKNOWLEDGE_AUTO_TRANSITION:
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                message: {
                    method: "acknowledge_auto_transition",
                    kwargs: {
                        "request_id": generateUUID(),
                        "case_number": action.payload.case_number,
                    }
                }
            });
            break;
        case CANCEL_AUTO_TRANSITION:
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                message: {
                    method: "cancel_auto_transition",
                    kwargs: {
                        "request_id": generateUUID(),
                        "case_number": action.payload?.case_number
                    }
                }
            });
            break;
        case SET_VEHICLE_STATUS:
            if (!action.payload.case?.auto_transition_received &&
                action.payload.case?.auto_transition_prompt_status === 'Visible') {
                dispatch({
                    type: SEND_MESSAGE_REQUEST,
                    message: {
                        method: "auto_transition_received",
                        kwargs: {
                            "request_id": generateUUID(),
                            "case_number": action.payload?.case?.case_number,
                        }
                    }
                });
            }
            break;
        case SUBMIT_CELLPHONE_SUCCESS:
            addItemToStorage('cellphone_number', action.payload);
            break;
        case UPDATE_VEHICLE_STATUS:
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                message: {
                    method: "update_vehicle_status",
                    kwargs: {
                        "vehicle": {
                            ...getState().vehicle,
                            location: JSON.parse(getItemFromStorage("location_current"))
                        },
                        "app_version": process.env.REACT_APP_VERSION,
                        "request_id": generateUUID(),
                    }
                }
            })
            break;
        case SET_AVAILABILITY:
            dispatch({
                type: SEND_MESSAGE_REQUEST,
                message: {
                    method: "set_vehicle_availability",
                    kwargs: {
                        "request_id": generateUUID(),
                        "availability": action.payload.vehicleAvailability,
                        "availability_reason": action.payload.availabilityReason,
                    }
                }
            });
            break;
        case CANCEL_ACTIVE_CASE:
            dispatch(setActiveCase(null));
            break;
        case LOGIN_USING_AUTH_TOKEN: {
            let cellphone_number = getItemFromStorage('cellphone_number');
            if (!cellphone_number) {
                dispatch(authenticationError("Cellphone number not registered on device, The cellphone number " +
                    "must be entered on the device before the authentication token may be used"));
                return
            }
            let deviceId = getItemFromStorage("deviceId")
            if (!deviceId) deviceId = uuidv4();
            dispatch(loginUsingAuthTokenAndCellphoneNumberandDeviceId(cellphone_number, action.payload, deviceId));
            break;
        }
        case LOGIN_USING_AUTH_TOKEN_AND_DEVICE_ID: {
            let cellphone_number = getItemFromStorage('cellphone_number');
            if (!cellphone_number) return;
            addItemToStorage("runningInApp", "true");
            dispatch(loginUsingAuthTokenAndCellphoneNumberandDeviceId(cellphone_number, action.payload.authToken, action.payload.deviceId));
            break;
        }
        case UPDATE_DEVICE_ID_SUCCESS: {
            addItemToStorage("deviceId", action.payload);
            dispatch(setDeviceId(action.payload))
            break;
        }
        case UPDATE_DEVICE_ID: {
            addItemToStorage("deviceId", action.payload);
            addItemToStorage("runningInApp", "true");
            let auth_token = getItemFromStorage("auth_token");
            dispatch(setDeviceId(action.payload))
            dispatch(updateDeviceIdOnBackend(auth_token, action.payload));
            break;
        }
        case UPDATE_AUTHORIZATION_TOKEN:
            if (!!action.payload && action.payload !== "undefined")
                addItemToStorage('auth_token', action.payload);
            break;
        case CASE_UPDATED:
            deleteItemFromStorage("vehicle");
            break;
        case LINK_CELLPHONE_SUCCESS: {
            dispatch(disconnectSocket());

            dispatch(setCrew({}))
            dispatch(setMessageQueue({}))
            dispatch(setCallSign(action.payload.call_sign))
            dispatch(setDeviceId(action.payload.device_id))

            addItemToStorage('crew', JSON.stringify({}));
            addItemToStorage('message_queue', JSON.stringify({}));
            addItemToStorage('auth_token', action.payload.token);
            addItemToStorage('cellphone_number', action.payload.cellphone_number);
            addItemToStorage('call_sign', action.payload.call_sign);
            addItemToStorage('deviceId', action.payload.device_id);

            dispatch(setAuthorization(true));
            dispatch(setAuthorizationToken(action.payload.token));
            break;
        }
        case REASSIGN_DEVICE_TO_VEHICLE: {
            dispatch(loginUsingAuthTokenAndCellphoneNumberandDeviceId(action.payload.cellphone_number, action.payload.token, action.payload.device_id));
            break;
        }
        case RELOAD_VEHICLE_FROM_SERVER: {
            const authToken = getItemFromStorage("auth_token");
            const cellphoneNumber = getItemFromStorage("cellphone_number");
            const connected = getState().socket.connected;
    
            if (authToken && cellphoneNumber && connected) {
                dispatch(getVehicleStatus(cellphoneNumber, authToken));
            }
            break;
        }
        default:
            break;
    }
};

export default vehicleMiddleware;