import React, {useEffect} from "react";
import Employee from "../components/employee";
import { Fab, useTheme} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import styled from "styled-components";
import {useSelector, useDispatch} from "react-redux";
import Card from "../components/card";
import {useHistory} from "react-router-dom";
import {deleteItemFromStorage, getItemFromStorage} from "../../redux/middleware/components/storage";
import {setCrew} from "../../redux/actions/crew";
import {setActiveCase} from "../../redux/actions/vehicle";

const ClockIn = () => {
    const crew = useSelector(state => state.crew.crew);
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();
    const activeCase = useSelector(state => state.vehicle.activeCase);

    const renderUnrosteredStaff = () => {
        return (
            <Card>
                <Center onClick={() => history.push('/clock-in/employee-sign-in')}>
                    <Title theme={theme}>No rostered staff</Title>
                    <SubTitle>Please clock-in using the button below</SubTitle>
                </Center>
            </Card>
        );
    };

    useEffect(() => {
        if (crew && Object.keys(crew).length === 0) {
            const crew = getItemFromStorage("crew");

            if (crew) {
                dispatch(setCrew(JSON.parse(crew)));
                deleteItemFromStorage("crew");
            }
        }
    }, [crew])

    useEffect(() => {
        if ((activeCase && Object.keys(activeCase).length === 0)) {
            const storedVehicle = getItemFromStorage("vehicle");
            if (storedVehicle) {
                const storedCase = JSON.parse(storedVehicle)?.activeCase;
                if (storedCase && Object.keys(storedCase).length) {
                    dispatch(setActiveCase(storedCase));
                    deleteItemFromStorage("vehicle");
                }
            }
        }
    }, [activeCase])

    const renderRosteredStaff = () => {
        return (
            <>
                {Object.keys(crew).map((employeeId) => {
                    let member = crew[employeeId];
                    return <Employee key={employeeId} employee={member} />;
                })}
            </>
        );
    };

    const renderStaff = () => {
        if (Object.keys(crew).length < 1)
            return renderUnrosteredStaff();
        return renderRosteredStaff();
    };

    const renderFAB = () => {
        return (
            <FAB
                color="primary"
                onClick={() => history.push('/clock-in/employee-sign-in')}>
                <AddIcon />
            </FAB>
        );
    }

    return (
        <>
            {renderStaff()}
            {renderFAB()}
        </>
    );
};

export default ClockIn;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const FAB = styled(Fab)`
  &.MuiButtonBase-root {
    position: absolute;
    right: 5%;
    bottom: 12%;
  }
`;

const Title = styled.div`
  display: flex;
  color: ${({theme}) => theme.palette.error.main};
`;

const SubTitle = styled.div`
  display: flex;
`;