import React from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import LandingPage from "./landingPage";

const UnauthenticatedRoutes = () => {
    return (
        <Switch>
            <Route exact path="/id/:deviceId">
                <LandingPage/>
            </Route>
            <Route exact path="/c/:authToken">
                <LandingPage/>
            </Route>
            <Route exact path="/">
                <LandingPage/>
            </Route>
            <Route>
                <LandingPage/>
            </Route>
            <Redirect to="/"/>
        </Switch>
    );
};

export default UnauthenticatedRoutes;