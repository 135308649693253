import React from "react";
import styled from "styled-components";
import Avatar from "../components/avatar";
import {useTheme} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {setActiveTab} from "../../redux/actions/application";

const DispatchStatus = ({position, status}) => {
    const theme = useTheme();
    const crew = useSelector(state => state.crew.crew);
    const availablityReason = useSelector(state => state.vehicle.vehicle_status_reason);
    const hasCrew = Object.keys(crew).length > 0;
    const history = useHistory();
    const dispatch = useDispatch();

    const navigateToClockIn = () => {
        history.replace(`/clock-in`);
        dispatch(setActiveTab("Crew"));
    };

    const navigateToVehicleStatus = () => {
        history.replace(`/set-vehicle-status`);
        dispatch(setActiveTab("Vehicle"));
    };

    return (
        <Container theme={theme} position={hasCrew ? position : 'top'} hasCrew={hasCrew}>
            <Status onClick={() => navigateToVehicleStatus()} theme={theme} availablity={availablityReason}>{availablityReason}</Status>
            {hasCrew && <Action onClick={() => navigateToClockIn()}>{Object.keys(crew).map((employeeId) => {
                let member = crew[employeeId];
                return <AvatarSpacing key={employeeId}>
                    <Avatar fullName={`${member.name} ${member.surname}`} />
                </AvatarSpacing>;
            })}</Action>}
        </Container>
    );
};

export default DispatchStatus;

const Status = styled.div`
    display: flex;

    padding: .25rem .5rem;
    margin: .25rem 0;
    
    color: ${({availablity, theme}) => ["available", "2nd Call"].includes(availablity) ? theme.palette.success.main : availablity !== "dispatched" ? theme.palette.error.main : theme.palette.warning.main};
    border-radius: .5rem;
    
    text-transform: capitalize;
`;

const Container = styled.div`
    display: flex;

    justify-content: ${({hasCrew}) => hasCrew ? "space-between" : "center"};
    align-items: center;

    width: calc(100% - ${({position}) => position === 'bottom' ? '4' : '2'}rem);
    height: calc(56px - 1rem);

    padding: 0.5rem 1rem;
    margin: 1rem 0;

    border-radius: 0.5rem;
    color: white;
    background: ${({theme}) => theme.palette.background.darker};

    position: ${({position}) => position === 'bottom' ? 'absolute' : 'relative'};
    bottom: ${({position}) => position === 'bottom' ? '60px' : 'unset'};

    transition: 300ms ease-out;
`;

const Action = styled.div`
    display: flex;
    margin-left: auto;
`;

const AvatarSpacing = styled.div`
    padding: 0 .25rem;
`;
