import * as actions from "../action_types/action-types";

export const APPLICATION_STARTED = "APPLICATION_STARTED";
export const SET_AUTHORIZATION = "SET_AUTHORIZATION";
export const SET_AUTHORIZATION_TOKEN = "SET_AUTHORIZATION_TOKEN";
export const GET_SOCKET_TOKEN_SUCCESS = "GET_SOCKET_TOKEN_SUCCESS";
export const GET_SOCKET_TOKEN_FAIL = "GET_SOCKET_TOKEN_FAIL";

export const SET_SUBSCRIPTION_FAIL = "SET_SUBSCRIPTION_FAIL";
export const SET_SUBSCRIPTION_SUCCESS = "SET_SUBSCRIPTION_SUCCESS";
export const SET_NAVIGATION_ENABLED = "SET_NAVIGATION_ENABLED";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const UPDATE_APP = "UPDATE_APP";
export const SET_SHOULD_UPDATE = "SET_SHOULD_UPDATE";
export const SET_APP_VERSION = "SET_APP_VERSION";

export const GET_VEHICLE_STATUS_SUCCESS = "GET_VEHICLE_STATUS_SUCCESS";
export const GET_VEHICLE_STATUS_FAIL = "GET_VEHICLE_STATUS_FAIL";

export const setNavigationEnabled = (enabled) => ({
  type: SET_NAVIGATION_ENABLED,
  payload: enabled
});

export const getVehicleStatusFail = (payload) => ({
  type: GET_VEHICLE_STATUS_FAIL,
  payload: payload
});

export const getVehicleStatusSuccess = (payload) => ({
  type: GET_VEHICLE_STATUS_SUCCESS,
  payload: payload
});

export const setSubscriptionFail = (payload, UUID) => ({
  type: SET_SUBSCRIPTION_FAIL,
  payload: {...payload, UUID}
});

export const setShouldUpdate = (shouldUpdate, version) => ({
  type: SET_SHOULD_UPDATE,
  payload: {shouldUpdate, version}
});

export const setAppVersion = (version) => ({
  type: SET_APP_VERSION,
  payload: {version}
});

export const setSubscriptionSuccess = (payload, UUID) => ({
  type: SET_SUBSCRIPTION_SUCCESS,
  payload: {payload, UUID}
});

export const setActiveTab = (tabName) => ({
  type: SET_ACTIVE_TAB,
  payload: tabName
});

export const startApp = (payload) => ({
  type: APPLICATION_STARTED,
  payload
});

export const setAuthorization = (payload) => ({
  type: SET_AUTHORIZATION,
  payload
});


export const setAuthorizationToken = (payload) => ({
  type: SET_AUTHORIZATION_TOKEN,
  payload
});


export const getSocketTokenSuccess = (payload) => {
  return {
    type: GET_SOCKET_TOKEN_SUCCESS,
    payload
  };
};

export const subcribeToNotifications = (subscription, authToken, UUID) => ({
  type: actions.API,
  payload: {
    url: 'rpc',
    method: actions.API_POST,
    data: {
      'controller': 'PushNotificationsController',
      'method': 'subscribe',
      'kwargs': {
        'request_id': UUID,
        'subscription': subscription,
        'token': authToken
      }
    },
    failure: (data) => setSubscriptionFail(data, UUID),
    success: (data) => setSubscriptionSuccess(data, UUID),
    label: 'login',
    authenticate: false,
  }
});

export const getVehicleStatus = (cellphoneNumber, authToken) => ({
  type: actions.API,
  payload: {
    url: 'rpc',
    method: actions.API_POST,
    data: {
      'controller': 'ApiController',
      'method': 'get_vehicle_status',
      'kwargs': {
        'cellphone_number': cellphoneNumber,
        'token': authToken
      }
    },
    failure: (data) => getVehicleStatusFail(data),
    success: (data) => getVehicleStatusSuccess(data),
    label: 'get_vehicle_status',
    authenticate: false
  }
});

