import {
    APPLICATION_STARTED,
    SET_ACTIVE_TAB,
    SET_AUTHORIZATION,
    SET_AUTHORIZATION_TOKEN,
    SET_NAVIGATION_ENABLED,
    SET_SHOULD_UPDATE,
    SET_APP_VERSION,
    SET_SUBSCRIPTION_FAIL,
    SET_SUBSCRIPTION_SUCCESS
} from "../actions/application";
import {UPDATE_AUTHORIZATION_TOKEN} from "../actions/vehicle";

const DEFAULT_STATE = {
    applicationStarted: false,
    authorized: false,
    authorizationToken: null,
    notificationSubscription: null,
    navigationEnabled: true,
    shouldUpdate: false,
    activeTab: "Home",
    minimum_version: process.env.REACT_APP_VERSION
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case SET_APP_VERSION:
            return {...state, minimum_version: action.payload.version }
        case SET_SHOULD_UPDATE:
            return {...state, shouldUpdate: action.payload.shouldUpdate, minimum_version: action.payload.version};
        case SET_ACTIVE_TAB:
            return {...state, activeTab: action.payload};
        case SET_NAVIGATION_ENABLED:
            return {...state, navigationEnabled: action.payload};
        case SET_SUBSCRIPTION_FAIL:
            return {...state, notificationSubscription: action.payload};
        case SET_SUBSCRIPTION_SUCCESS:
            return {...state, notificationSubscription: true};
        case APPLICATION_STARTED:
            return {...state, applicationStarted: true};
        case SET_AUTHORIZATION:
            return {...state, authorized: action.payload};
        case SET_AUTHORIZATION_TOKEN:
        case UPDATE_AUTHORIZATION_TOKEN:
            return {...state, authorizationToken: action.payload, authorized: true};
        default:
            return state;
    }
}
