import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch, useHistory} from 'react-router-dom';
import styled from "styled-components";
import {setNavigationEnabled} from "../../redux/actions/application";
import CancellationReasons from "./components/CancellationReasons";
import FacilitiesSelect from "./components/FacilitiesSelect";
import PatientInformation from "./patientInformation";

const CaptureRouter = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const activeCase = useSelector(state => state.vehicle.activeCase);
    const showCaseCancelled = useSelector(state => !!state.vehicle.cancelledCase);

    useEffect(() => {
        dispatch(setNavigationEnabled(false));
    }, [activeCase, dispatch]);

    useEffect(() => {
        if (showCaseCancelled) navToHome();
    }, [showCaseCancelled]);

    const navToHome = () => {
        dispatch(setNavigationEnabled(true));
        history.replace("/");
    }

    return (
        <ScrollContainer>
            <Switch>
                <Route exact path="/capture/cancel-reason">
                    <CancellationReasons />
                </Route>
                <Route exact path="/capture/facility">
                    <FacilitiesSelect />
                </Route>
                <Route exact path="/capture/patient-information">
                    <PatientInformation />
                </Route>
            </Switch>
        </ScrollContainer>
    );
};

export default CaptureRouter;

// height is full-screen - header - tabbar - title inset
const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
`;
