import {UPDATE_CANCELLATION_REASONS, UPDATE_FACILITIES_LIST} from "../actions/staticData";
import {FACILITIES} from "../../constants";

const DEFAULT_STATE = {
    facilities: {
        version: 0,
        facilities: [...FACILITIES]
    },
    cancellationReasons: {
        version: 1,
        reasons: {
            "New case": [],
            "Acknowledged": [
                "Cancelled by caller",
                "Incorrect dispatch",
                "Vehicle not available"
            ],
            "Mobile incident": [
                "Cancelled by caller",
                "No scene found",
                "Cancelled on route"
            ],
            "At incident": [
                "Ambulance completing EPRF",
                "CAT138",
                "COVID-19 Asymptomatic",
                "Dead on Arrival",
                "HEMS - Netcare 911",
                "HEMS - Other",
                "No Injuries on Scene",
                "Own Transport",
                "Patient Refuses Assessment",
                "Patient Transported by Private Services",
                "Patient Transported by Provincial Services",
                "RV completing EPRF"
            ],
            "Mobile hospital": [
                "Patient absconded",
                "Death in care"
            ]
        }
    }
};

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case UPDATE_CANCELLATION_REASONS:
            return {
                ...state,
                cancellationReasons: {
                    version: action.payload.version,
                    reasons: {...action.payload.reasons}
                }
            }
        case UPDATE_FACILITIES_LIST:
            return {
                ...state,
                facilities: {
                    version: action.payload.version,
                    facilities: [...action.payload.facilities]
                }
            }

        default:
            return state;
    }
}
