import { dateFromValidSAID } from "../PatientInfo/utils";

export const requiredValidator = (errorMessage) => (value) => value ? undefined : errorMessage;

export const lengthValidator = (errorMessage, length) => (value) => `${value}`.length === length ? undefined : errorMessage;

export const dateValidator = (errorMessage) => (value) => /^([1-9][0-9]{3})\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(value) ? undefined : errorMessage;

export const requiredDateValidator = (requiredErrorMessage, dateErrorMessage) => (value) => {
    const requiredValidation = requiredValidator(requiredErrorMessage);
    const dateValidation = dateValidator(dateErrorMessage);
    return requiredValidation(value) ?? dateValidation(value);
}

export const southAfricanIdValidator = (errorMessage) => (value) => {
    // Luhn algorithm as provided by https://github.com/JamesEggers1/node-luhn/blob/master/src/luhn.js
    let trimmed = String(value).replace(/[\s]/g, "")
    let length = trimmed.length
    let odd = false
    let total = 0
    let calc
    let calc2;

    if (!/^[0-9]+$/.test(trimmed)) {
        return false;
    }

    for (var i = length; i > 0; i--) {
        calc = parseInt(trimmed.charAt(i - 1));
        if (!odd) {
            total += calc;
        } else {
            calc2 = calc * 2;

            switch (calc2) {
            case 10: calc2 = 1; break;
            case 12: calc2 = 3; break;
            case 14: calc2 = 5; break;
            case 16: calc2 = 7; break;
            case 18: calc2 = 9; break;
            default: calc2 = calc2;
            }
            total += calc2;
        }
        odd = !odd;
    }

    return (total !== 0 && (total % 10) === 0) && length === 13 ? undefined : errorMessage;
}

export const requireLengthValidator = (requiredErrorMessage, idErrorMessage, length) => (value) => {
    const requiredValidation = requiredValidator(requiredErrorMessage);
    const SouthAfricanIdValidation = lengthValidator(idErrorMessage,length);
    return requiredValidation(value) ?? SouthAfricanIdValidation(value);
}

export const saIdDateValidator = (errorMessage) => (value) => dateFromValidSAID(value) ? undefined : errorMessage;


export const requiredSAIdValidator = (requiredErrorMessage, idErrorMessage, length) => (value) => {
    const requiredValidation = requiredValidator(requiredErrorMessage);
    const SouthAfricanIdValidation = saIdDateValidator(idErrorMessage);
    const SouthAfricanIdLengthValidation = lengthValidator(idErrorMessage,length);
    return requiredValidation(value) ?? SouthAfricanIdValidation(value) ?? SouthAfricanIdLengthValidation(value);
}