export const addItemToStorage = (key, value) => {
    localStorage.setItem(key, value)
}

export const getItemFromStorage = (key) => {
    return localStorage.getItem(key)
}

export const deleteItemFromStorage = (key) => {
    localStorage.removeItem(key)
}